import { AppDispatch } from "../../index";
import { IUser, IUserReg } from "../../../models/IUser";
import { AuthActionEnum, ClearDataAction, SetAuthAction, SetDataAction, SetErrorAction, SetIsLoadingAction, SetTokenAction, SetUserAction } from "./types";
import { IToken } from "../../../models/IToken";
import TokenApiRequest from "../../../api/User/Token";
import jwt from 'jwt-decode';
import UserApiRequest from "../../../api/User/Users";
import EmployersApiRequest from "../../../api/Employers/Employers";
import { DataPressActionCreators } from "../dataPressItem/action-creator";

export const AuthActionCreators = {
    setUser: (user: IUser): SetUserAction => ({ type: AuthActionEnum.SET_USER, payload: user }),
    setToken: (token: IToken): SetTokenAction => ({ type: AuthActionEnum.SET_TOKEN, payload: token }),
    setIsAuth: (auth: boolean): SetAuthAction => ({ type: AuthActionEnum.SET_AUTH, payload: auth }),
    setErr: (payload: string): SetErrorAction => ({ type: AuthActionEnum.SET_ERROR, payload }),
    setData: (payload: string): SetDataAction => ({ type: AuthActionEnum.SET_DATA, payload }),
    clearData: (): ClearDataAction => ({ type: AuthActionEnum.CLEAR_DATA }),


    setIsLoading: (payload: boolean): SetIsLoadingAction => ({ type: AuthActionEnum.SET_IS_LOADING, payload }),
    login: (username: string, password: string) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));
        const mockUser = { username, password };
        const userToken = new EmployersApiRequest();
        const userData = new UserApiRequest();
        const employersData = new EmployersApiRequest();
        setTimeout(()=>{
            if (mockUser.username.length === 0 || mockUser.password.length === 0) {
                dispatch(AuthActionCreators.setErr('Некорректный логин или пароль'));
                dispatch(AuthActionCreators.setIsLoading(false));
                return;
            }
            try {
                userToken.login({ body: mockUser }).then((resp)=>{
                    if (resp.success) {
                        //@ts-ignore
                        const tokens = resp.data as IToken;
                        dispatch(AuthActionCreators.setToken(tokens));
                        localStorage.setItem('access', tokens.access || '')
                        localStorage.setItem('refresh', tokens.refresh || '')
                        //@ts-ignore
                        const decodeJwt = jwt(tokens.refresh) || '';
                        //@ts-ignore
                        if (decodeJwt && decodeJwt.user_id) {
                            //@ts-ignore
                            userData.getById({id: decodeJwt.user_id + '/'}).then((resp)=>{
                                
                                if(resp.success){
                                    localStorage.setItem('auth', "true");
                                    localStorage.setItem('username', mockUser.username);
                                    if (resp.data) {
                                        //@ts-ignore
                                        const dataUser: { id?: string, email?: string, first_name?: string, last_name?: string, patronymic?: string, phone_number?:string } = resp.data;

                                     
                                    
                                        const user = {
                                        id: dataUser.id,
                                        email: dataUser.email,
                                        phone_number: dataUser.phone_number,
                                        first_name: dataUser.first_name,
                                        last_name: dataUser.last_name,
                                        patronymic: dataUser.patronymic
                                        };
                                    
                                        localStorage.setItem('account', JSON.stringify(user));
                                        dispatch(AuthActionCreators.setIsAuth(true));
                                    }
                                    //@ts-ignore
                                    employersData.getByUser().then((resp)=>{
                                        if(resp.success){
                                            if (resp.data) {
                                                //@ts-ignore
                                                const dataEmployers: { id?: string, name?: string, inn?: string, legal_address?: string, contact_person_fio?: string, phone_number?:string, description?:string, site?:string,contact_person_email?:string,user?:string  } = resp.data;
                                            
                               

                                                const employers = {
                                                    id: dataEmployers.id,
                                                    name: dataEmployers.name,
                                                    inn: dataEmployers.inn,
                                                    legal_address: dataEmployers.legal_address,
                                                    contact_person_fio: dataEmployers.contact_person_fio,
                                                    phone_number: dataEmployers.phone_number,
                                                    description: dataEmployers.description,
                                                    site: dataEmployers.site,
                                                    contact_person_email: dataEmployers.contact_person_email,
                                                    user: dataEmployers.user,
                                                };
                                            
                                                localStorage.setItem('accountEmployers', JSON.stringify(employers));
                                                dispatch(AuthActionCreators.setIsAuth(true));
                                            }
                                        }
                                    })
                                    
                                    //@ts-ignore
                                    dispatch(AuthActionCreators.setUser({username: resp.data.username, password: mockUser.password, firstname: resp.data.first_name, lastname: resp.data.last_name, patronymic: resp.data.patronymic}));
                                    // localStorage.setItem('user',${username: resp.data.username, firstname: resp.data.first_name, lastname: resp.data.last_name, patronymic: resp.data.patronymic});
                                } else {
                                    dispatch(AuthActionCreators.setErr('Ошибка получения пользователя'));
                                }
                            })
                          
                        }
                      
                    } else {
                        dispatch(AuthActionCreators.setErr('Произошла ошибка авторизации'));
                    }
                });
               
            } catch (e) {
                dispatch(AuthActionCreators.setErr('Произошла ошибка при авторизации'));
            }
            dispatch(AuthActionCreators.setIsLoading(false));
        }, 2000)
      
    },
    registration: (body:IUserReg) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));
        
        const employersApi = new EmployersApiRequest();
        setTimeout(()=>{
            //@ts-ignore
            if (body.contact_person_fio === undefined|| 
                 //@ts-ignore
                 body.inn === undefined ||
                 //@ts-ignore
                 body.legal_address === undefined ||
                 //@ts-ignore
                 body.name === undefined ||
                 //@ts-ignore
                 body.phone_number === undefined
            ) {
                dispatch(AuthActionCreators.setErr('Не заполнены обязательные поля'));
                dispatch(AuthActionCreators.setIsLoading(false));
                return;
            }
            try {
                employersApi.sendToModeration(body).then((resp:any)=>{
                    if (resp.success) {
                        console.log('res', resp.data);
                        dispatch(AuthActionCreators.setIsLoading(false));
                            dispatch(AuthActionCreators.setData(resp.data));
                            dispatch(DataPressActionCreators.clearDataPress())

                        setTimeout(() => {
                            
                            dispatch(AuthActionCreators.clearData()); // Предполагается, что у вас есть экшен для очистки данных
                        }, 5000);
                        
                    }
                });
               
            } catch (e) {
                dispatch(AuthActionCreators.setErr('Произошла ошибка при авторизации'));
            }
            dispatch(AuthActionCreators.setIsLoading(false));
        }, 0)
      
    },
    logout: () => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));
        localStorage.removeItem('auth');
        localStorage.removeItem('username');
        localStorage.removeItem('applicant');
        localStorage.removeItem('account');
        localStorage.removeItem('access');
        localStorage.removeItem('accountEmployers');
        localStorage.removeItem('refresh');
        localStorage.removeItem('userVK');
        dispatch(AuthActionCreators.setIsAuth(false));
        dispatch(AuthActionCreators.setUser({} as IUser));
        dispatch(AuthActionCreators.setIsLoading(false));
    }
}
