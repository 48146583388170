import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import FormInput from "../FormInput/FormInput";
import Buttons from "../Buttons/Buttons";
import { IApplications } from "../../models/IApplications";
import { fieldToArray } from "../UI/functions/functions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import AvatarPicker from "../AvatarPicker/AvatarPicker";

interface IProfileProp {
  dataOption: IApplications | undefined;
  dataProfile: IApplications | undefined;
  onSave: () => void;
}

const ProfileComponents: FC<IProfileProp> = ({
  dataOption,
  dataProfile,
  onSave,
}) => {
  const dispatch = useDispatch();
  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  useEffect(() => {
    dataProfile &&
      fieldToArray(dataProfile).map((item) => {
        handleChange(item.key, item.value);
      });
  }, [dataProfile]);

  return (
    <div className="formContainer">
      <div className="formContainerField">
        {dataOption &&
          fieldToArray(dataOption)?.map((item) => {
            if (item.key === "id") {
              return;
            }
            if (item.key === "avatar") {
              return (
                <AvatarPicker
                  avatarUrl={dataPress[item.key]}
                  setFiles={(e) => {
                    //@ts-ignore
                    handleChange(item.key, e[0]);
                  }}
                />
              );
            }

            return (
              <FormInput
                style={""}
                value={dataPress[item.key]}
                onChange={(e) => {
                  handleChange(item.key, e);
                }}
                subInput={item.value.label}
                required={item.value.required}
                type={item.value.type}
                error={""}
                keyData={""}
                friedlyInput={true}
                key={item.key}
                textArea={item.key === "resume" && true}
              />
            );
          })}
        <Buttons
          className="orangeBtnForm"
          text={"Сохранить"}
          onClick={onSave}
        />
      </div>
    </div>
  );
};

export default ProfileComponents;
