import React, { FC, useEffect, useState } from "react";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import Modal from "../Modal/Modal";
import "./styles.scss";
import FormInput from "../FormInput/FormInput";
import JobApiRequest from "../../api/Job/Job";
import { IJobVacation } from "../../models/IJobVacation";
import { fieldToArray } from "../UI/functions/functions";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";
import { IError } from "../../models/IError";

const VacationWork: FC = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const jobsApi = new JobApiRequest();
  const [optionVacation, setOptionVacation] = useState<IJobVacation>();
  const [isErr, setIsErr] = useState<IError>({
    id: "",
    message: "",
    type: "error",
  });

  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const dispatch = useDispatch();

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  useEffect(() => {
    jobsApi.options().then((resp) => {
      if (resp.success) {
        setOptionVacation(
          //@ts-ignore
          resp?.data?.actions?.["work-on-holiday"] as IJobVacation
        );
      }
    });
  }, []);

  const sentVacation = () => {
    jobsApi.workOnHoliday(dataPress).then((resp) => {
      if (resp.success) {
        setIsOpenModal(false);
        setIsErr({ id: "", message: "Отправлено", type: "success" });
      } else {
        setIsErr({
          id: "",
          message: "Заплните обязательные поля",
          type: "error",
        });
      }
    });
  };

  return (
    <div className="containerVacation">
      {isErr.message != "" && (
        <ErrorMessage
          //@ts-ignore
          type={isErr.type}
          message={isErr.message}
          onClose={() => {
            setIsErr({ id: "", message: "", type: "]" });
          }}
        />
      )}
      <Modal
        content={
          <div className="modalContainer">
            <div className="modalHeader">
              <h1 className="titleModal">Отправить заявку</h1>
              <h3 className="textModal">
                Заполните все поля и получите предложение от работодателя по
                работе на каникулах
              </h3>
            </div>
            <div className="formModal">
              {optionVacation &&
                fieldToArray(optionVacation).map((item) => {
                  return (
                    <FormInput
                      style={""}
                      value={dataPress[item.key]}
                      onChange={(e) => {
                        handleChange(item.key, e);
                      }}
                      subInput={item.value.label}
                      required={item.value.required}
                      error={""}
                      type={item.value.type}
                      keyData={item.key}
                      friedlyInput={true}
                      options={item.value.choices}
                    />
                  );
                })}

              <Buttons
                text={"Отправить"}
                onClick={() => {
                  sentVacation();
                }}
                className="orangeButton"
              />
              <p className="subtextModal">
                Заявка будет отправлена и рассмотрена Центром занятости
                Белгородской области
              </p>
            </div>
          </div>
        }
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        classModal="classModal"
        classBgModal="classBgModal"
      />
      <div>
        <h1 className="vacationTitle">
          Найди работу на каникулах в своем муниципальном образовании
        </h1>
        <h5 className="vacationText">
          Сервис Центра занятости Белгородской области
        </h5>
      </div>
      <Buttons
        ico={icons.lightning}
        text={"Работать на каникулах"}
        className="whiteButtonVaccation"
        onClick={() => setIsOpenModal(true)}
      />
    </div>
  );
};

export default VacationWork;
