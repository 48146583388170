export const API_AAPPLICANTS_MODEL = {
    entity: 'applicants',
    url: 'applicants/',
    methods: {
        getByUser:{
            url:"get-by-user"
        },
        applicantUpdate:{
            url:"update/"
        },
    },
}