import React, { FC, useEffect, useState } from "react";
import icons from "../../assets/icons/icons";
import Buttons from "../Buttons/Buttons";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { AuthActionCreators } from "../../store/reducers/auth/action-creator";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RouteNames, navDate } from "../../routes";
import UserApiRequest from "../../api/User/Users";
import { AuthVkActionCreators } from "../../store/reducers/authVk/action-creatorVk";
import Skeleton from "react-loading-skeleton";
import apiConfig from "../../api/apiConfig";

const Header: FC = () => {
  const dispatch = useDispatch();
  const user =
    localStorage.getItem("applicant") &&
    JSON.parse(localStorage.getItem("applicant") || "");

  const navigation = useNavigate();
  const urlParams = window.location.pathname.split("/");
  const [queryParameters] = useSearchParams();
  const access = localStorage.getItem("access");
  const accessVK = localStorage.getItem("accessVK");
  const account = localStorage.getItem("account");

  useEffect(() => {
    // if (accessVK !== undefined && accessVK !== "" && accessVK !== null) {
    const accessToken = queryParameters.get("access_token");
    const refreshToken = queryParameters.get("refresh_token");
    //@ts-ignore
    dispatch(AuthVkActionCreators.loginVK(accessToken, refreshToken));
    // }
  }, [accessVK]);

  const userApi = new UserApiRequest();
  const { error, isLoading } = useTypeSelector((state) => state.authVkReducer);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenBurger, setIsOpenBurger] = useState(false);

  const isProfile = urlParams[1] === "profile";
  const isMyJob = urlParams[1] === "myjob";
  const isCreateJob = urlParams[1] === "createjob";

  const logout = () => {
    //@ts-ignore
    dispatch(AuthActionCreators.logout());
    //@ts-ignore
    dispatch(AuthVkActionCreators.logout());
    navigation(RouteNames.HOMEPAGE);
  };

  const commonButtons = [
    {
      className: "whiteOrangeButton",
      ico: icons.logOut,
      text: "Выход",

      onClick: () => logout(),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 1100);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenVk = () => {
    userApi.loginVk().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        window.location.replace(resp?.data ? resp?.data.vk_auth_url : "");
      }
    });
  };

  return (
    <header className={`header ${isMobile && "mobile"}`}>
      <div className="containerHeader">
        <div
          className="headerLogoText"
          onClick={() => {
            navigation(RouteNames.HOMEPAGE);
          }}
        >
          <img src={icons.Logo} className="logo" />
          <h4 className="logoText">
            Губернатор и правительство Белгородской области
          </h4>
          <img src={icons.LogoSvoi} className="logoSvoi" />
        </div>
        {isMobile && (
          <div
            onClick={() => setIsOpenBurger(!isOpenBurger)}
            className={`burgerMenu ${isOpenBurger && "active"}`}
          ></div>
        )}
        <div className="userHeaderContainer">
          <nav className={`nav ${isOpenBurger && "active"}`}>
            {isProfile && <Buttons {...commonButtons[0]} />}
            {isMyJob && (
              <>
                <Buttons
                  className="orangeButton"
                  text={"Создать вакансию"}
                  onClick={() => navigation(RouteNames.VACANCYCREATE)}
                />
                <Buttons {...commonButtons[0]} />
              </>
            )}
            {isCreateJob && (
              <>
                <Buttons
                  className="orangeButton"
                  text={"Создать вакансию"}
                  onClick={() => navigation(RouteNames.VACANCYCREATE)}
                />
                <Buttons {...commonButtons[0]} />
              </>
            )}
            {!isProfile && !isMyJob && !isCreateJob && (
              <>
                {navDate.map((item) =>
                  user && item.id === 5 ? (
                    <></>
                  ) : item.id === 6 ? (
                    <></>
                  ) : (
                    <Link
                      key={item.id}
                      className={`navItem ${item.id === 5 && "work"}`}
                      to={item?.link || ""}
                    >
                      {item.name}
                    </Link>
                  )
                )}
                {isLoading ? (
                  <Buttons
                    className="whiteButton disable"
                    ico={icons.Vkontakte}
                    text={"Войти через"}
                    //@ts-ignore
                    onClick={() => {}}
                  />
                ) : user ? (
                  <Buttons
                    className="whiteButton"
                    image={`${apiConfig.baseUrlMedia}${user?.avatar?.file}`}
                    stileImage={"imageButton"}
                    text={
                      //@ts-ignore
                      `${user?.fio}`
                    }
                    //@ts-ignore
                    onClick={() => navigation(RouteNames.PROFILE)}
                  />
                ) : (
                  !account && (
                    <Buttons
                      className="whiteButton"
                      ico={icons.Vkontakte}
                      text={"Войти через"}
                      //@ts-ignore
                      onClick={() => handleOpenVk()}
                    />
                  )
                )}
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
