import React, { FC, Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CoverComponent from "../../components/CoverComponent/CoverComponent";
import VacancyСard from "../../components/VacancyСard/VacancyСard";
import "./styles.scss";
import icons from "../../assets/icons/icons";
import NewsCard, { NewsItem } from "../../components/NewsCard/NewsCard";
import Buttons from "../../components/Buttons/Buttons";
import Footer from "../../components/Footer/Footer";
import SearchComponent, {
  Category,
} from "../../components/SearchComponent/SearchComponent";
import Modal from "../../components/Modal/Modal";
import FeedApiRequest from "../../api/Feed/Feed";
import { Link, useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import SingularComponent from "react-singular-component";
import JobApiRequest from "../../api/Job/Job";
import ErrorMessage from "../../components/UI/ErrorMassage/ErrorMassage";
import { IError } from "../../models/IError";
import { useDispatch } from "react-redux";
import { SearchActionCreators } from "../../store/reducers/searchPressItem/action-creator";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import VacationWork from "../../components/VacationWork/VacationWork";

interface NewsProps {
  results: NewsItem[];
  count: number;
  next: string | null;
  previous: string | null;
}

interface UsefullItem {
  id: number;
  link: string;
  name: string;
}

interface UsefullProps {
  results: UsefullItem[];
  count: number;
  next: string | null;
  previous: string | null;
}

const Reestr: FC = () => {
  const apiFeed = new FeedApiRequest();
  const jobsApi = new JobApiRequest();

  const navigation = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenMOdalError, setIsOpenModalError] = useState<boolean>(false);
  const [isToggleSearch, setToggleSearch] = useState<boolean>(false);
  const [activeJob, setActiveJob] = useState<any>();
  const [dataEvents, setDataEvents] = useState<any>();
  const [dataExcursion, setDataExcursion] = useState<NewsProps>();
  const [dataUsefull, setDataUseful] = useState<UsefullProps>();
  const [jobData, setJobData] = useState<any>([]);
  const [jobLength, setJobLength] = useState<number>(0);
  const [activitiesData, setActivitesData] = useState<any>([]);
  const [jobSearchActivite, setJobSearchActivite] = useState<any>([]);
  const [jobSearchCategory, setJobSearchCategory] = useState<any>([]);
  const [cateGoriesData, setCategoriesData] = useState<any>([]);

  const dispatch = useDispatch();

  const [singularPriority, setSingularPriority] = useState<number>(1);
  const [isErr, setIsErr] = useState<IError>({
    id: "",
    message: "",
    type: "error",
  });

  useEffect(() => {
    jobsApi.list({ urlParams: `?archived=false` }).then((resp: any) => {
      if (resp.success) {
        //@ts-ignore
        setJobData(resp.data.results);
        setJobLength(resp.data.count);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
    // jobsApi.activities().then((resp: any) => {
    //   if (resp.success) {
    //     setActivitesData(resp.data);
    //   } else {
    //     setIsErr({ id: "", message: resp.message, type: "error" });
    //   }
    // });
    // jobsApi.categories().then((resp: any) => {
    //   if (resp.success) {
    //     setCategoriesData(resp.data);
    //   } else {
    //     setIsErr({ id: "", message: resp.message, type: "error" });
    //   }
    // });

    apiFeed.getEvents().then((resp) => {
      if (resp.success) {
        setDataEvents(resp.data);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
    apiFeed.getExcursions().then((resp) => {
      if (resp.success) {
        setDataExcursion(resp.data as NewsProps);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
    apiFeed.getUsefulResours().then((resp) => {
      if (resp.success) {
        setDataUseful(resp.data as UsefullProps);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
  }, []);

  const handleJobClick = (dataJob: any) => {
    setIsOpenModal(true);
    setActiveJob(dataJob);
  };
  const handleJobActiveClick = (dataJob: any) => {
    jobsApi.respond(dataJob.id).then((resp) => {
      if (resp.success) {
        setIsOpenModal(false);
        jobsApi.list({ urlParams: `?archived=false` }).then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setJobData(resp.data.results);
          } else {
            setIsErr({ id: "", message: resp.message, type: "error" });
          }
        });
      } else {
        setIsOpenModalError(true);
        // setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
  };

  const handleSearch = () => {
    navigation(RouteNames.VACANCY);
    // jobsApi
    //   .list({
    //     urlParams: `?archived=false&search=${text ? text : ""}&job_type=${
    //       isToggleSearch ? 2 : ""
    //     }${
    //       jobSearchCategory.length !== 0
    //         ? jobSearchCategory
    //             .map((item: any) => `&job_category=${item.id}`)
    //             .join("")
    //         : ""
    //     }${
    //       jobSearchActivite.length !== 0
    //         ? jobSearchActivite
    //             .map((item: any) => `&job_activity=${item.id}`)
    //             .join("")
    //         : ""
    //     }`,
    //   })
    //   .then((resp: any) => {
    //     if (resp.success) {
    //       //@ts-ignore
    //       setJobData(resp.data.results);
    //     } else {
    //       setIsErr({ id: "", message: resp.message, type: "error" });
    //     }
    //   });
  };

  // useEffect(() => {
  //   handleSearch();
  // }, [jobSearchCategory, isToggleSearch, jobSearchActivite]);

  return (
    <Fragment>
      {isErr.message != "" && (
        <ErrorMessage
          //@ts-ignore
          type={isErr.type}
          message={isErr.message}
          onClose={() => {
            setIsErr({ id: "", message: "", type: "]" });
          }}
        />
      )}
      <Modal
        content={
          activeJob && (
            <VacancyСard
              //@ts-ignore
              key={activeJob?.id}
              //@ts-ignore
              dataCard={activeJob}
              onClick={() => {
                //@ts-ignore
                handleJobActiveClick(activeJob);
              }}
              modal={true}
              isError={(e: IError) => setIsErr(e)}
            />
          )
        }
        onClose={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        classModal="modalVacancy"
      />
      <Modal
        content={
          <div className="modalContainer">
            <div className="modalHeader">
              <h1 className="titleModal">Подтвердите данные</h1>
              <h3 className="textModal">
                Чтобы откликнуться на вакансию вам необходимо заполнить
                недостающие данные в своем профиле.
              </h3>
            </div>
            <Buttons
              text={"Перейти в профиль"}
              onClick={() => {
                navigation(RouteNames.PROFILE);
              }}
              className="orangeButton"
            />
          </div>
        }
        onClose={() => setIsOpenModalError(false)}
        isOpen={isOpenMOdalError}
        classModal="classModal"
        classBgModal="classBgModal"
      />

      <section className="section">
        <Header />
        <CoverComponent lenghInternship={jobLength} />
        <SingularComponent
          singularKey="SingleInput"
          singularPriority={singularPriority}
        >
          <SearchComponent
            //@ts-ignore
            onSearch={(e) => {
              handleSearch();
            }}
            //@ts-ignore
            // onToggle={(e) => {
            //   setToggleSearch(!isToggleSearch);
            // }}
            categoryActiveData={(e: Category) => {
              setJobSearchCategory(e);
            }}
            activitesActiveData={(e: Category) => {
              setJobSearchActivite(e);
            }}
          />
        </SingularComponent>

        <VacationWork />
        {jobData && jobData.length === 0 ? (
          <p>Вакансий нет</p>
        ) : (
          <Fragment>
            <h2 className="sectionTitle">Новые вакансии</h2>
            <div className="sectionCardContainer">
              {jobData &&
                jobData
                  .slice(-6)
                  .reverse()
                  .map((item: any) => (
                    <VacancyСard
                      key={item.id}
                      //@ts-ignore
                      dataCard={item}
                      onClick={() => {
                        handleJobActiveClick(item);
                      }}
                      onRead={() => {
                        handleJobClick(item);
                      }}
                      isError={(e: IError) => setIsErr(e)}
                    />
                  ))}
            </div>
            <Buttons
              text={"Другие вакансии"}
              ico={icons.arrowRightOrange}
              onClick={() => navigation(RouteNames.VACANCY)}
              className="continueButton"
            ></Buttons>
          </Fragment>
        )}
        {dataEvents && dataEvents?.results?.length !== 0 && (
          <Fragment>
            <h2 className="sectionTitle">События</h2>
            <div className="sectionCardContainer">
              {dataEvents &&
                dataEvents?.results
                  ?.slice(-6)
                  .reverse()
                  .map((item: NewsItem) => (
                    <NewsCard key={item.id} dataCard={item} news={true} />
                  ))}
            </div>
            <Buttons
              text={"События ранее"}
              ico={icons.arrowRightOrange}
              onClick={() => navigation(RouteNames.EVENTS)}
              className="continueButton"
            ></Buttons>
          </Fragment>
        )}

        {dataExcursion && dataExcursion?.results?.length !== 0 && (
          <Fragment>
            <h2 className="sectionTitle">Экскурсии</h2>
            <div className="sectionCardContainer">
              {dataExcursion?.results
                ?.slice(-3)
                .reverse()
                .map((item: NewsItem) => (
                  <NewsCard key={item.id} dataCard={item} />
                ))}
            </div>
            <Buttons
              text={"Все экскурсии"}
              ico={icons.arrowRightOrange}
              onClick={() => navigation(RouteNames.EXCURSIOS)}
              className="continueButton"
            ></Buttons>
          </Fragment>
        )}
        {dataUsefull?.results?.length != 0 && (
          <Fragment>
            <h2 className="sectionTitle">Полезные ресурсы</h2>

            <div className="useFullResours">
              {dataUsefull?.results?.map((item) => (
                <Link
                  key={item.id}
                  className="whiteButton"
                  target="_blank"
                  to={item.link}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Fragment>
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

export default Reestr;
