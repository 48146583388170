export const API_JOB_MODEL = {
    entity: 'job-openings',
    url: 'job-openings/',
    methods: {
        moveToArchive:{
            url:'move-to-archive/'
        },
        downToArchive:{
            url:'restore-from-archive/'
        },
        moveApplicantFound:{
            url:'found-applicants/'
        },
        respond:{
            url:'respond/'
        },
        activities:{
            url:'activities/'
        },
        categories:{
            url:'categories/'
        },
        workOnHoliday:{
            url:'work-on-holiday/'
        },
    },
}