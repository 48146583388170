import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_AAPPLICANTS_MODEL } from "./const";

class ApplicantsApiRequest extends BaseModelAPI {
    constructor() {
        super(API_AAPPLICANTS_MODEL.url);
    }

    async getByUser<T>() {
        return this.makeRequest<T>(axiosClient.get, {method: API_AAPPLICANTS_MODEL.methods.getByUser.url});
    }
    async applicantUpdateOption<T>() {
        return this.makeRequest<T>(axiosClient.options, {method: API_AAPPLICANTS_MODEL.methods.applicantUpdate.url});
    }
}

export default ApplicantsApiRequest