const apiConfig = {
    baseUrl: 'https://rabota.belregion.ru/api/',
    baseUrlContragent: 'https://rabota.belregion.ru/',
    baseUrlMedia: 'https://rabota.belregion.ru/media/',
    // apiKey: ''
    // baseUrl: 'http://51.250.126.124:8099/api/',
    // baseUrlContragent: 'http://51.250.126.124:8099/',
    // baseUrlMedia: 'http://51.250.126.124:8099/media/',
    apiKey: ''
}

export default apiConfig;