import React, { FC, Fragment } from "react";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import {
  formatDateIntlDate,
  formatDateIntlDateTime,
  formatDateIntlTimeDate,
} from "../UI/functions/functions";
import icons from "../../assets/icons/icons";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";
import JobApiRequest from "../../api/Job/Job";
import apiConfig from "../../api/apiConfig";

const MyVacancyСard: FC = ({
  //@ts-ignore
  dataCard,
  //@ts-ignore
  onClick,
  //@ts-ignore
  modal,
  //@ts-ignore
  onRead,
  //@ts-ignore
  onArchive,
  //@ts-ignore
  onApplicantFound,
  //@ts-ignore
  archive,
}) => {
  const formatCurrency = (value: string) =>
    new Intl.NumberFormat("ru", {
      style: "currency",
      currency: "RUB",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parseFloat(value));

  const navigate = useNavigate();

  // Получаем дату создания карточки и преобразуем ее в объект Date
  const createdAt = new Date(dataCard?.created_at);

  // Получаем текущую дату
  const currentDate = new Date();

  //@ts-ignore
  const diffInDays = Math.floor(
    //@ts-ignore
    (currentDate - createdAt) / (1000 * 60 * 60 * 24)
  );

  // Вычисляем количество дней, которое осталось до максимального значения 30 дней
  const daysLeft = 30 - diffInDays;

  return (
    <div
      id={dataCard.id}
      key={dataCard.id}
      className={`cardContainer ${modal && "modalActive"}`}
    >
      <div className="cardContainerBody">
        <div className="myCardHeader">
          <div className="cardHeaderTag">
            <p className="cardTag__type">{dataCard.job_type}</p>
            <p className="cardTag">{dataCard.job_category}</p>
            <p className="cardTag">{dataCard.job_activity}</p>
          </div>
          {!archive ? (
            <p className="time">
              <img src={icons.Clock}></img>
              {`${daysLeft} дней`}
            </p>
          ) : (
            <Buttons
              text={"Вернуть из архива"}
              className="returnToArchive"
              onClick={() => onArchive(dataCard.id)}
            />
          )}
        </div>
        <div className="cardTitleContainer">
          <h4 className="cardTitle">{dataCard.title}</h4>
          <h5 className="cardPrice">
            {dataCard.salary_min && dataCard.salary_max
              ? `${formatCurrency(dataCard.salary_min)}-${formatCurrency(
                  dataCard.salary_max
                )}`
              : dataCard.salary_max
              ? `до ${formatCurrency(dataCard.salary_max)}`
              : dataCard.salary_min
              ? `от ${formatCurrency(dataCard.salary_min)}`
              : ""}
          </h5>
        </div>
        <p className="cardDescription">{dataCard.description}</p>
        {dataCard?.applicants.length != 0 && (
          <Fragment>
            <h4>Откликнулись на вакансию</h4>
            <div className={`containerResponsesApplicants`}>
              <Fragment>
                {dataCard?.applicants?.map((item: any) => {
                  return (
                    <div className="containerApplicants">
                      <img
                        src={`${apiConfig.baseUrlMedia}${item.avatar}`}
                        key={item.id}
                        className={`vacancyResponsesAvatarts`}
                      ></img>
                      <div className="applicantsInfo">
                        <h1>{item.fio}</h1>
                        <h2>{formatDateIntlDate(item.birth_date)}</h2>
                        <a href={`tel:7${item.phone_number}`}>
                          {`+7${item.phone_number}`}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            </div>
          </Fragment>
        )}
      </div>

      {!archive && (
        <div className="footerCard">
          <div className="buttonContainer">
            <Buttons
              ico={icons.edit}
              text={"Редактировать"}
              onClick={() =>
                navigate(RouteNames.VACANCYCREATE, {
                  state: { idJob: dataCard.id, type: "redact" },
                })
              }
              className="whiteButtonCard"
            />
            <Buttons
              ico={icons.file}
              text={"Убрать в архив"}
              //@ts-ignore
              onClick={() => onArchive(dataCard.id)}
              className="whiteButtonCard"
            />
            <Buttons
              ico={icons.userCheck}
              text={"Сотрудник найден"}
              //@ts-ignore
              onClick={() => {
                onApplicantFound(dataCard.id);
              }}
              className="orangeButtonCard"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyVacancyСard;
