import BaseModelAPI from "../BaseModelAPI";
import axiosClient from "../axiosClient";
import { API_JOB_MODEL } from "./const";

class JobApiRequest extends BaseModelAPI {
    constructor() {
        super(API_JOB_MODEL.url);
    }

    async moveToArchive<T>(id:string, body?: any) {
        return this.makeRequest<T>(axiosClient.post, {id: id  + "/", method: API_JOB_MODEL.methods.moveToArchive.url, body});
    }
    async downToArchive<T>(id:string, body?: any) {
        return this.makeRequest<T>(axiosClient.post, {id: id  + "/", method: API_JOB_MODEL.methods.downToArchive.url, body});
    }
    async respond<T>(id:string) {
        return this.makeRequest<T>(axiosClient.post, { method: API_JOB_MODEL.methods.respond.url , id: id  + "/"});
    }
    async moveApplicantFound<T>(id:string, body?: any) {
        return this.makeRequest<T>(axiosClient.post, {id: id  + "/", method: API_JOB_MODEL.methods.moveApplicantFound.url, body});
    }
    async activities<T>() {
        return this.makeRequest<T>(axiosClient.get, { method: API_JOB_MODEL.methods.activities.url});
    }
    async categories<T>() {
        return this.makeRequest<T>(axiosClient.get, { method: API_JOB_MODEL.methods.categories.url});
    }
    async workOnHoliday<T>(body?:any) {
        return this.makeRequest<T>(axiosClient.post, { method: API_JOB_MODEL.methods.workOnHoliday.url, body});
    }
}

export default JobApiRequest