// index.ts
import authReducer from './auth/authReducer';
import authVkReducer from './authVk/authVkReducer';
import dataPressReducer from './dataPressItem/dataPressItemReducer';
import SearchReducer from './searchPressItem/dataPressItemReducer';

export default {
  authReducer,
  dataPressReducer,
  authVkReducer,
  SearchReducer,
};
