import React, { FC, Fragment, useState } from "react";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import {
  formatDateCustom,
  formatDateIntlDateTime,
  formatDateIntlTimeDate,
} from "../UI/functions/functions";
import apiConfig from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";

//@ts-ignore
const VacancyCard: FC = ({ dataCard, onClick, modal, onRead, isError }) => {
  const user =
    localStorage.getItem("applicant") &&
    JSON.parse(localStorage.getItem("applicant") || "");
  const accountEmp =
    localStorage.getItem("account") &&
    JSON.parse(localStorage.getItem("account") || "");

  const formatCurrency = (value: string) =>
    new Intl.NumberFormat("ru", {
      style: "currency",
      currency: "RUB",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parseFloat(value));

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (user && !accountEmp) {
      onClick();
    } else {
      isError({
        id: "",
        message: "Вы должны быть авторизированы через Вконтакте",
        type: "warning",
      });
    }
  };

  return (
    <div
      id={dataCard.id}
      key={dataCard.id}
      className={`cardContainer ${modal && "modalActive"}`}
      onClick={onRead}
    >
      <div className="cardContainerBody">
        <div className="cardHeaderTag">
          <p className="cardTag__type">{dataCard.job_type}</p>
          <p className="cardTag">{dataCard.job_category}</p>
          <p className="cardTag">{dataCard.job_activity}</p>
        </div>
        <div className="cardTitleContainer">
          <h4 className="cardTitle">{dataCard.title}</h4>
          <h5 className="cardPrice">
            {dataCard.salary_min && dataCard.salary_max
              ? `${formatCurrency(dataCard.salary_min)}-${formatCurrency(
                  dataCard.salary_max
                )}`
              : dataCard.salary_max
              ? `до ${formatCurrency(dataCard.salary_max)}`
              : dataCard.salary_min
              ? `от ${formatCurrency(dataCard.salary_min)}`
              : ""}
          </h5>
        </div>
        <p className={`cardDescription ${modal && "full"}`}>
          {dataCard.description}
        </p>
        {dataCard?.applicants.length != 0 && (
          <div
            className={`containerResponses ${
              dataCard?.applicants.length === 1 && "one"
            }`}
          >
            <Fragment>
              {dataCard?.applicants?.map((item: any) => {
                return (
                  <img
                    src={`${apiConfig.baseUrlMedia}${item.avatar}`}
                    key={item.id}
                    className={`responsesAvatarts ${
                      dataCard?.applicants.length === 1 && "one"
                    }`}
                  ></img>
                );
              })}
              {dataCard?.applicants?.length > 8 && (
                <p className="numberResponses">{`+${
                  dataCard.applicants.length - 8
                }`}</p>
              )}
            </Fragment>
          </div>
        )}
      </div>

      <div className="footerCard">
        <div className={`footerInfoCompany ${modal && "active"}`}>
          <h4 className="cardOrgName">{dataCard.employer_name}</h4>
          {modal && (
            <Fragment>
              <p className="cardFooterDescription">
                {dataCard.employer_description && dataCard.employer_description}
              </p>
              <a
                href={`${dataCard.employer_site && dataCard.employer_site}`}
                target="_blank"
                className="cardFooterLink"
              >
                {dataCard.employer_site && dataCard.employer_site}
              </a>
              {dataCard.municipality && (
                <p className="cardFooterDescription">
                  {dataCard.municipality && dataCard.municipality}
                </p>
              )}
            </Fragment>
          )}
        </div>
        <div className="footerCardCity">
          <p className="cardCity">{dataCard.employer_address}</p>

          <p className="cardCityDate">
            {formatDateCustom(dataCard.created_at)}
          </p>
        </div>
        {dataCard &&
        dataCard.applicants &&
        !dataCard.applicants.some(
          (applicant: any) => applicant?.id === user?.id
        ) ? (
          <Buttons
            text={"Откликнуться"}
            //@ts-ignore
            onClick={handleCardClick}
            className="whiteButtonCard"
          />
        ) : (
          <h4 className="textComplete">Вы откликнулись</h4>
        )}
      </div>
    </div>
  );
};

export default VacancyCard;
