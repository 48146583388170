import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import FormInput from "../FormInput/FormInput";
import Buttons from "../Buttons/Buttons";
import ToggleButton from "../ToggleButton/ToggleButton";
import { SearchActionCreators } from "../../store/reducers/searchPressItem/action-creator";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import JobApiRequest from "../../api/Job/Job";

export interface Category {
  id: number;
  name: string;
}

const SearchComponent: FC = ({
  onSearch,
  onFilter,
  dataActivites,
  dataCategory,
  onToggle,
  categoryActiveData,
  activitesActiveData,
}: any) => {
  const [isText, setIsText] = useState<string>("");
  const [activeActivites, setActiveActivites] = useState<Category[]>([]);
  const [activeCategories, setActiveCategories] = useState<Category[]>([]);
  const [optionFilter, setOptionFilter] = useState<any>([]);
  const dispatch = useDispatch();

  const jobApi = new JobApiRequest();

  const searchString = useTypeSelector(
    (state: any) => state.SearchReducer.dataPress
  );

  const toggleActivites = (category: Category) => {
    setActiveActivites((prevCategories) => {
      const categoryIndex = prevCategories.findIndex(
        (c) => c.id === category.id
      );
      if (categoryIndex !== -1) {
        const updatedCategories = [...prevCategories];
        updatedCategories.splice(categoryIndex, 1);
        return updatedCategories;
      } else {
        return [...prevCategories, category];
      }
    });
  };
  const toggleCategory = (category: Category) => {
    setActiveCategories((prevCategories) => {
      const categoryIndex = prevCategories.findIndex(
        (c) => c.id === category.id
      );
      if (categoryIndex !== -1) {
        const updatedCategories = [...prevCategories];
        updatedCategories.splice(categoryIndex, 1);
        return updatedCategories;
      } else {
        return [...prevCategories, category];
      }
    });
  };

  useEffect(() => {
    categoryActiveData(activeCategories);
    activitesActiveData(activeActivites);
  }, [activeCategories, activeActivites]);

  useEffect(() => {
    jobApi.options().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        setOptionFilter(resp.data?.actions["filter-list"]);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="containerSearch">
        <div className="searchContainerInput">
          <FormInput
            style={"searchInput"}
            placeholder="Какую работу вы ищете?"
            value={searchString.search}
            onChange={(e) => {
              dispatch(SearchActionCreators.setDataPress("search", e));
            }}
            subInput={undefined}
            required={false}
            error={""}
            keyData={""}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(isText); // Выполняем поиск при нажатии Enter
              }
            }}
          ></FormInput>
          {onToggle && (
            <ToggleButton
              onToggle={(e) => {
                onToggle(e);
              }}
              text={"Ищу стажировку"}
              formDataKey={""}
              checked={undefined}
              styleContainer={"toggleSearch"}
            />
          )}
        </div>
        <Buttons
          text={"Найти"}
          onClick={() => {
            onSearch(isText);
          }}
          className="btnOrange"
        />
      </div>
      <div className="categoryContainer">
        {onFilter && (
          <FormInput
            style={"searchFilter"}
            value={undefined}
            onChange={(e) => {
              onFilter(e);
            }}
            subInput={undefined}
            required={false}
            error={""}
            keyData={""}
            friedlyInput
            placeholder="Белгородская область"
            options={optionFilter?.municipality?.choices}
          />
        )}
        {dataActivites && dataActivites.length !== 0 && (
          <Fragment>
            {dataActivites.map((item: Category) => {
              const isActive = activeActivites.some((c) => c.id === item.id);
              const categoryClass = isActive
                ? "categoryItem active"
                : "categoryItem";

              return (
                <div
                  key={item.id}
                  className={categoryClass}
                  onClick={() => toggleActivites(item)}
                >
                  {item.name}
                </div>
              );
            })}
          </Fragment>
        )}
        {dataCategory && dataCategory.length !== 0 && (
          <Fragment>
            {dataCategory.map((item: Category) => {
              const isActive = activeCategories.some((c) => c.id === item.id);
              const categoryClass = isActive
                ? "categoryItem active"
                : "categoryItem";

              return (
                <div
                  key={item.id}
                  className={categoryClass}
                  onClick={() => toggleCategory(item)}
                >
                  {item.name}
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default SearchComponent;
