import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import icons from "../../assets/icons/icons";
import FeedApiRequest from "../../api/Feed/Feed";
import NewsCard, { NewsItem } from "../../components/NewsCard/NewsCard";
import Pagination from "../../components/Pagination/Pagination";

interface NewsProps {
  results: NewsItem[];
  count: number;
  next: string | null;
  previous: string | null;
}

const ExcursiosPage: FC = () => {
  const navigation = useNavigate();
  const apiFeed = new FeedApiRequest();
  const [dataExcursion, setDataExcursion] = useState<any>();

  useEffect(() => {
    apiFeed.getExcursions().then((resp) => {
      if (resp.success) {
        setDataExcursion(resp.data as NewsProps);
      }
    });
  }, []);

  return (
    <Fragment>
      <section className="section">
        <Header />
        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">Экскурсии</h1>
        </div>

        <div className="sectionCardContainer">
          {dataExcursion &&
            dataExcursion?.results
              ?.slice(-6)
              .reverse()
              .map((item: NewsItem) => (
                <NewsCard key={item.id} dataCard={item} />
              ))}
        </div>

        {/* <Pagination
          //@ts-ignore
          respData={dataPagination}
          handlePage={(e) => {
            handleUpdatePage(e);
          }}
          handleSearch={(e) => {
            setStringSearch(e);
          }}
        /> */}
      </section>
      <Footer />
    </Fragment>
  );
};

export default ExcursiosPage;
