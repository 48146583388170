import React, { FC, Fragment } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Buttons from "../../components/Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";

const UserAgreementPage: FC = () => {
  const navigation = useNavigate();
  return (
    <Fragment>
      <section className="section">
        <Header />
        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">Пользовательское соглашение</h1>
        </div>
        <div className="sectionCardContainer__two">
          <p>
            <b>1. Общие положения</b> <br />
            1.1. Настоящая Политика в области обработки и защиты персональных
            данных на платформе «Первое рабочее место» (далее – Политика):
            <br />· разработана в целях обеспечения реализации требований
            законодательства Российской Федерации в области обработки
            персональных данных; <br />· раскрывает основные категории
            персональных данных, обрабатываемых Министерством по делам молодежи
            Белгородской области (далее соответственно – Оператор) на платформе
            «Первое рабочее место» (далее – Платформа); <br />· устанавливает
            цели, способы и принципы обработки Оператором персональных данных,
            права и обязанности Оператора при обработке персональных данных,
            права субъектов персональных данных, а также перечень мер,
            применяемых Оператором в целях обеспечения безопасности персональных
            данных при их обработке; <br />· является документом, декларирующим
            основы деятельности Оператора при обработке персональных данных на
            Платформе. <br />
            1.2. Основные понятия: <br />· персональные данные – любая
            информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (субъекту персональных данных);{" "}
            <br />· оператор – государственный орган, муниципальный орган,
            юридическое или физическое лицо, самостоятельно или совместно с
            другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными;{" "}
            <br />· пользователи – участники автоматизированной информационной
            системы, перечень которых установлен Федеральным законом от
            27.07.2006 № 152-ФЗ «О персональных данных»; <br />· третьи лица –
            иные лица, задействованные в реализации, развитии (модернизации) и
            сопровождении Платформы; <br />· обработка персональных данных –
            любое действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без
            использования таких средств с персональными данными, включая сбор,
            запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных; <br />· автоматизированная
            обработка персональных данных – обработка персональных данных с
            помощью средств вычислительной техники; <br />· распространение
            персональных данных – действия, направленные на раскрытие
            персональных данных неопределенному кругу лиц; <br />·
            предоставление персональных данных – действия, направленные на
            раскрытие персональных данных определенному лицу или определенному
            кругу лиц; <br />· блокирование персональных данных – временное
            прекращение обработки персональных данных (за исключением случаев,
            если обработка необходима для уточнения персональных данных); <br />
            · уничтожение персональных данных – действия, в результате которых
            становится невозможным восстановить содержание персональных данных в
            информационной системе персональных данных и (или) в результате
            которых уничтожаются материальные носители персональных данных;{" "}
            <br />· обезличивание персональных данных – действия, в результате
            которых становится невозможным без использования дополнительной
            информации определить принадлежность персональных данных конкретному
            субъекту персональных данных; <br />· информационная система
            персональных данных – совокупность содержащихся в базах данных
            персональных данных и обеспечивающих их обработку информационных
            технологий и технических средств; <br />
            1.3. Обязанности Оператора: <br />
            1.3.1. При сборе персональных данных Оператор обязан предоставить
            субъекту персональных данных по его просьбе информацию,
            предусмотренную частью 7 статьи 14 Федерального закона от 27.07.2006
            № 152-ФЗ «О персональных данных». <br />
            1.3.2. Если персональные данные получены не от субъекта персональных
            данных, за исключением случаев, предусмотренных частью 4 статьи 18
            Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»,
            Оператор до начала обработки таких персональных данных обязан
            предоставить субъекту персональных данных информацию,
            предусмотренную частью 3 статьи 18 Федерального закона от 27.07.2006
            № 152-ФЗ «О персональных данных». <br />
            1.3.3. Оператор обязан реализовать право субъекта персональных
            данных на доступ к его персональным данным в порядке,
            предусмотренном статьей 14 Федерального закона от 27.07.2006 №
            152-ФЗ «О персональных данных» и локальными актами Оператора. <br />
            1.3.4. Оператор обязан разъяснить субъекту персональных данных:{" "}
            <br />· порядок принятия решения на основании исключительно
            автоматизированной обработки его персональных данных и возможные
            юридические последствия такого решения, включая возможность заявить
            возражение против такого решения; <br />· порядок защиты субъектом
            персональных данных своих прав и законных интересов; <br />·
            юридические последствия отказа предоставить его персональные данные
            или дать согласие на их обработку в случаях, предусмотренных частью
            2 статьи 18 Федерального закона от 27.07.2006 № 152-ФЗ «О
            персональных данных». <br />
            1.4. Права Оператора: <br />
            1.4.1. Обрабатывать персональные данные субъекта персональных данных
            в соответствии с целями сбора персональных данных, указанных в
            пункте 2.1 настоящей Политики.
            <br /> 1.4.2. Самостоятельно определять состав и перечень мер,
            необходимых и достаточных для обеспечения выполнения обязанностей,
            предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О
            персональных данных».
            <br />
            1.4.3. В случае отзыва субъектом персональных данных согласия на
            обработку персональных данных Оператор вправе продолжить обработку
            персональных данных без согласия субъекта персональных данных в
            случаях, указанных в пунктах 2 11 части 1 статьи 6, части 2 статьи
            10 и части 2 статьи 11 Федерального закона от 27.07.2006 № 152-ФЗ «О
            персональных данных».
            <br /> 1.4.4. Вносить изменения в настоящую Политику без согласия
            пользователей в случае существенных изменений законодательства
            Российской Федерации в области защиты персональных данных. <br />
            1.5. Права субъектов персональных данных: <br />
            1.5.1. Получать сведения об обработке его персональных данных
            Оператором, и источник их получения. <br />
            1.5.2. Требовать от Оператора уточнения обрабатываемых им
            персональных данных субъекта персональных данных, их блокирования
            или уничтожения в случае, если персональные данные являются
            неполными, устаревшими, неточными, незаконно полученными или не
            являются необходимыми для заявленной цели обработки, а также
            принимать предусмотренные законом меры по защите своих прав. <br />
            1.5.3. Право субъекта персональных данных на доступ к его
            персональным данным может быть ограничено в соответствии с
            федеральными законами, в том числе в случаях, предусмотренных частью
            8 статьи 14 Федерального закона от 27.07.2006 № 152-ФЗ «О
            персональных данных».
            <br />
            1.5.4. Обжаловать действия или бездействие Оператора путем обращения
            в уполномоченный орган по защите прав субъектов персональных данных
            или в судебном порядке. <br />
            1.5.5. Защищать свои права и законные интересы, в том числе
            возмещать убытки и (или) компенсацию морального вреда в судебном
            порядке. <br />
            1.5.6. Требовать от Оператора извещения всех лиц, которым ранее были
            сообщены неверные или неполные персональные данные, обо всех
            произведенных в них исключениях, исправлениях или дополнениях.
            <br /> 1.5.7. Получать от Оператора информацию о сроках обработки
            своих персональных данных, в том числе о сроках их хранения. <br />
            1.6. Обязанности субъекта персональных данных: <br />
            1.6.1. Предоставить полные и достоверные персональные данные. <br />
            1.6.2. Обновить, дополнить предоставленные персональные данные в
            случае их изменения. 2. Цели сбора персональных данных 2.1. Цели
            обработки персональных данных включают в себя: <br />· содействие
            занятости населения, в том числе посредством размещения информации о
            гражданах, ищущих работу; <br />
            · оказание в электронном виде государственных услуг в сфере
            занятости населения;
            <br />· создание, использование и хранение электронных документов,
            связанных с работой, а также с выполнением работ и оказанием услуг
            по договорам гражданско-правового характера, прохождением
            практической подготовки, стажировки, профессионального обучения или
            получения дополнительного профессионального образования, авторским
            договорам, заключенным с физическими лицами; <br />· формирование
            аналитической информации о трудоустройстве граждан в Российской
            Федерации. <br />
            3. Правовые основания обработки персональных данных <br />
            3.1. Обработка персональных данных осуществляется Оператором в
            соответствии со следующими нормативными правовыми актами Российской
            Федерации: <br />· Конституция Российской Федерации; <br />·
            Гражданский кодекс Российской Федерации ; <br />· Трудовой кодекс
            Российской Федерации; <br />· Федеральный закон от 02.05.2006 №
            59-ФЗ «О порядке рассмотрения обращения граждан Российской
            Федерации»; <br />· Федеральный закон от 06.04.2011 № 63-ФЗ «Об
            электронной подписи»; <br />· Закон РФ от 19.04.1991 № 1032-1 «О
            занятости населения в Российской Федерации»;
            <br />· Постановление Правительства РФ от 30.06.2004 № 324 «Об
            утверждении Положения о Федеральной службе по труду и занятости»;{" "}
            <br />· Приказ Минтруда России от 28.01.2022 № 27н «Об утверждении
            стандарта деятельности по осуществлению полномочия в сфере занятости
            населения по оказанию государственной услуги содействия гражданам в
            поиске подходящей работы»; <br />· Приказ Минтруда России от
            28.01.2022 № 26н «Об утверждении стандарта деятельности по
            осуществлению полномочия в сфере занятости населения по оказанию
            государственной услуги содействия работодателям в подборе
            необходимых работников»; <br />· Приказ Минтруда России от
            27.04.2022 № 266н «Об утверждении Стандарта деятельности по
            осуществлению полномочия в сфере занятости населения по оказанию
            государственной услуги по социальной адаптации безработных граждан
            на рынке труда»; <br />· Приказ Минтруда России от 28.01.2022 № 25н
            «Об утверждении стандарта деятельности по осуществлению полномочия в
            сфере занятости населения по оказанию государственной услуги по
            организации временного трудоустройства несовершеннолетних граждан в
            возрасте от 14 до 18 лет в свободное от учебы время, безработных
            граждан, испытывающих трудности в поиске работы, безработных граждан
            в возрасте от 18 до 25 лет, имеющих среднее профессиональное
            образование или высшее образование и ищущих работу в течение года с
            даты выдачи им документа об образовании и о квалификации»; <br />·
            Приказ Минтруда России от 25.02.2022 № 84н «Об утверждении Стандарта
            процесса осуществления полномочия в сфере занятости населения по
            организации и проведению специальных мероприятий по профилированию
            граждан, зарегистрированных в целях поиска подходящей работы, и
            работодателей»; <br />· иные нормативные правовые акты Российской
            Федерации, во исполнение которых и в соответствии с которыми
            Оператор осуществляет обработку персональных данных. 4. Объем и
            категории обрабатываемых персональных данных, категории субъектов
            персональных данных
            <br /> 4.1. Оператором обрабатываются следующие категории
            персональных данных: <br />· фотография; <br />· фамилия, имя,
            отчество (при наличии); <br />· информация о желаемой должности;{" "}
            <br />· пол, дата рождения; <br />· гражданство; <br />· адрес места
            жительства (пребывания); <br />· информация о страховом номере
            индивидуального лицевого счета застрахованного лица в системе
            персонифицированного учета Фонда пенсионного и социального
            страхования Российской Федерации; <br />· информация о серии и
            номере основного документа, удостоверяющего личность, сведения о
            дате выдачи указанного документа и выдавшем его органе; <br />·
            семейное положение; <br />· наличие и даты рождения детей; <br />·
            социальный статус (инвалид, пенсионер, женщина в отпуске по уходу за
            ребенком до 3 лет и т.д.); <br />· образование (уровень образования,
            учебное заведение, факультет, год окончания обучения, название
            дипломной работы, квалификация по диплому, специальность по диплому,
            серия и номер диплома, дата выдачи диплома); <br />· дополнительное
            образование (название курса, учебное заведение, год окончания){" "}
            <br />· свидетельства и сертификаты (свидетельство о независимой
            оценке квалификации, регистрационный номер и дата выдачи); <br />·
            иные документы (информация о медицинской книжке, водительском
            удостоверении, сертификатах, удостоверениях, званиях); <br />·
            желаемая должность; <br />· желаемая заработная плата; <br />·
            профессия (специальность), квалификация; <br />· опыт работы
            (организация(-и), должность, начало работы, окончание работы,
            обязанности и достижения); <br />· стаж работы по профессии
            (специальности), в должности; <br />· информация о дате готовности
            приступить к работе; <br />· ключевые навыки; <br />· пожелания к
            вакансии (график работы, тип занятости, готовность к переобучению и
            командировкам); <br />· владение языками (язык и уровень владения);{" "}
            <br />· номер телефона; <br />· адрес электронной почты; <br />·
            социальные сети; <br />· свидетельство о рождении, которое
            необходимо для идентификации несовершеннолетнего гражданина на
            Платформе; <br />· информация о фактах обращения в органы службы
            занятости населения и получении государственных услуг в сфере
            занятости населения; <br />· информация о нахождении на
            регистрационном учете в органах занятости в качестве ищущего
            подходящую работу или безработного.
            <br /> 4.2. Также осуществляется пассивный сбор персональных данных
            о текущем подключении в части статистических сведений: <br />·
            посещенные страницы;
            <br />· количество посещений страниц; <br />· информация о
            перемещении по страницам сайта; <br />· длительность
            пользовательской сессии; <br />· браузер пользователя; <br />· дата
            и время посещения сайта. <br />
            4.3. Источники получения персональных данных. <br />
            4.3.1. Получение сведений о персональных данных осуществляется на
            основании документов и информации: Представленных лично субъектом
            персональных данных при совершении действий: • по регистрации в
            качестве пользователя Платформы на сайте, расположенном в сети
            Интернет по адресу https://rabota.belregion.ru/ (далее – сайт);{" "}
            <br />
            5. Порядок и условия обработки персональных данных
            <br />
            5.1. Оператор в своей деятельности обеспечивает соблюдение принципов
            обработки персональных данных, указанных в статье 5 Федерального
            закона от 27.06.2006 № 152 ФЗ «О персональных данных». <br />
            5.2. С персональными данными совершаются следующие действия: <br />·
            сбор; <br />· хранение; <br />· обработка, в том числе запись,
            систематизация, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передача (предоставление и
            доступ, а также с дополнительного согласия субъекта персональных
            данных – распространение), обезличивание, блокирование, удаление и
            уничтожение. <br />
            5.3. Обработка персональных данных субъектов персональных данных,
            указанных в пункте 4.1 настоящей Политики, осуществляется с согласия
            указанных лиц и в соответствии с целями, определенными в пункте 2.1
            настоящей Политики. <br />
            5.4. Оператор не осуществляет трансграничную передачу персональных
            данных. <br />
            5.5. В целях соблюдения законодательства Российской Федерации в
            области защиты персональных данных, для достижения целей обработки,
            а также в интересах и с согласия субъектов персональных данных
            Оператор в ходе своей деятельности обеспечивает: <br />·
            предоставление санкционированного доступа пользователей Платформы к
            информации, размещаемой субъектом персональных данных на сайте;{" "}
            <br />· предоставление персональных данных пользователей Платформы
            третьим лицам с согласия субъекта персональных данных в целях
            содействия занятости пользователей, в том числе при прохождения
            профессионального обучения и получении дополнительного
            профессионального образования, прохождения практической подготовки
            на основании соответствующих договоров (соглашений).
            <br /> 5.6. Оператор и третьи лица, получившие доступ к персональным
            данным, обязаны не раскрывать иным лицам и не распространять
            персональные данные без согласия субъекта персональных данных, если
            иное не предусмотрено федеральным законом. <br />
            5.7. Оператор, осуществляющий сбор персональных данных с
            использованием информационно-телекоммуникационных сетей, обязан
            опубликовать в соответствующей информационно-телекоммуникационной
            сети документ, определяющий его политику в отношении обработки
            персональных данных, и сведения о реализуемых требованиях к защите
            персональных данных, а также обеспечить возможность доступа к
            указанному документу с использованием средств соответствующей
            информационно-телекоммуникационной сети.
            <br /> 5.8. В случае подтверждения факта неправомерности
            персональных данных Оператор прекращает неправомерную обработку
            персональных данных или обеспечивает прекращение неправомерной
            обработки персональных данных.
            <br /> 6. Сроки обработки (хранения) персональных данных <br />
            6.1. Началом срока обработки персональных данных считается момент их
            получения Оператором. <br />
            6.2. Оператор осуществляет хранение персональных данных в форме,
            позволяющей определить субъект персональных данных, не дольше, чем
            того требуют цели их обработки, кроме случаев, когда срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных. <br />
            6.3. При достижении целей обработки персональных данных, а также в
            случае отзыва субъектом персональных данных согласия на их обработку
            персональные данные подлежат уничтожению, если: <br />· оператор не
            вправе осуществлять обработку без согласия субъекта персональных
            данных на основаниях, предусмотренных Федеральный закон от
            27.07.2006 № 152-ФЗ «О персональных данных» или иными федеральными
            законами; <br />· иное не предусмотрено договором, стороной
            которого, выгодоприобретателем или поручителем по которому является
            субъект персональных данных; <br />· иное не предусмотрено иным
            соглашением между оператором и субъектом персональных данных. <br />
            6.4. Оператор обязан сообщить информацию об осуществляемой им
            обработке персональных данных субъекта по запросу субъекта
            персональных данных, его представителя, а также уполномоченного
            органа по защите прав субъектов персональных данных. <br />
            6.5. Сроки обработки и хранения персональных данных субъектов
            персональных данных соответствует сроку существования личного
            кабинета пользователя. <br />
            7. Уточнение, блокирование и уничтожение персональных данных <br />
            7.1. Целью уточнения персональных данных, в том числе обновления и
            изменения, является обеспечение достоверности, полноты и
            актуальности персональных данных, обрабатываемых Оператором. <br />
            7.2. Уточнение персональных данных осуществляется Оператором по
            собственной инициативе, по требованию субъекта персональных данных
            или его представителя, по требованию уполномоченного органа по
            защите прав субъектов персональных данных в случаях, когда
            установлено, что персональные данные являются неполными,
            устаревшими, недостоверными. <br />
            7.3. Целью блокирования персональных данных является временное
            прекращение обработки персональных данных до момента устранения
            обстоятельств, послуживших основанием для блокирования персональных
            данных. <br />
            7.4. Блокирование персональных данных осуществляется Оператором по
            требованию субъекта персональных данных или его представителя, а
            также по требованию уполномоченного органа по защите прав субъектов
            персональных данных в случае выявления недостоверных персональных
            данных или неправомерных действий с ними. <br />
            7.5. Уничтожение или обезличивание персональных данных
            осуществляется Оператором: <br />· по достижении цели обработки
            персональных данных; <br />· в случае утраты необходимости в
            достижении целей обработки персональных данных; <br />· в случае
            отзыва субъектом персональных данных согласия на обработку своих
            персональных данных; <br />· по требованию субъекта персональных
            данных или уполномоченного органа по защите прав субъектов
            персональных данных в случае выявления фактов совершения
            неправомерных действий с персональными данными, когда устранить
            соответствующие нарушения не представляется возможным. <br />
            7.6. При уничтожении материальных носителей персональных данных
            составляется акт об уничтожении носителей, содержащих персональные
            данные. <br />
            8. Меры по обеспечению безопасности персональных данных при их
            обработке Оператор при обработке персональных данных принимает все
            необходимые правовые, организационные и технические меры для защиты
            персональных данных от неправомерного или случайного доступа к ним,
            уничтожения, изменения, блокирования, копирования, предоставления,
            распространения персональных данных, а также от иных неправомерных
            действий в отношении персональных данных. Обеспечение безопасности
            персональных данных достигается Оператором посредством следующих
            мер: <br />· назначение ответственных лиц за организацию обработки
            персональных данных; <br />· назначение лиц, непосредственно
            осуществляющих обработку персональных данных; <br />· осуществление
            внутреннего контроля соответствия обработки персональных данных
            Федеральному закону от 27.06.2006 № 152-ФЗ «О персональных данных» и
            принятым в соответствии с ним нормативным правовым актам,
            требованиям к защите персональных данных, внутренним
            распорядительным документам Оператора; <br />· ознакомление
            работников Оператора, непосредственно осуществляющих обработку
            персональных данных, с положениями законодательства Российской
            Федерации в области персональных данных, в том числе с требованиями
            к защите персональных данных, внутренних распорядительных документов
            Оператора в отношении обработки персональных данных, и (или)
            обучение указанных работников; <br />· применение организационных и
            технических мер по обеспечению безопасности персональных данных при
            их обработке в информационных системах персональных данных,
            необходимых для выполнения требований к защите персональных данных;{" "}
            <br />· обнаружение фактов несанкционированного доступа к
            персональным данным и принятием мер;
            <br />· восстановление персональных данных, модифицированных или
            уничтоженных вследствие несанкционированного доступа к ним; <br />·
            контроль за принимаемыми мерами по обеспечению безопасности
            персональных данных. <br />
            9. Заключительные положения <br />
            9.1. Политика является внутренним документом Оператора, подлежащим
            размещению в открытом доступе на сайте. <br />
            9.2. Политика подлежит изменению, дополнению в случае появления
            новых законодательных актов и специальных нормативных документов по
            обработке и защите персональных данных. <br />
            9.3. Контроль исполнения положений настоящей Политики осуществляется
            лицом, назначенным Оператором ответственным за организацию обработки
            персональных данных Оператора. <br />
            9.4. Ответственность должностных лиц Оператора, имеющих доступ к
            персональным данным, за невыполнение требований норм, регулирующих
            обработку и защиту персональных данных, определяется в соответствии
            с законодательством Российской Федерации и локальными актами
          </p>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default UserAgreementPage;
