import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import icons from "../../assets/icons/icons";
import ProfileComponents from "../../components/ProfileComponents/ProfileComponents";
import CoverComponent from "../../components/CoverComponent/CoverComponent";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import VacancyСard from "../../components/VacancyСard/VacancyСard";
import Modal from "../../components/Modal/Modal";
import JobApiRequest from "../../api/Job/Job";
import MyVacancyСard from "../../components/MyVacancyСard/MyVacancyСard";
import Pagination, {
  ResponseData,
} from "../../components/Pagination/Pagination";

const MyVacancyPage: FC = () => {
  const navigation = useNavigate();
  const storedUserEmployers = localStorage.getItem("accountEmployers");
  const userEmployers = storedUserEmployers
    ? JSON.parse(storedUserEmployers)
    : null;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeJob, setActiveJob] = useState<any>();
  const [jobData, setJobData] = useState<any>([]);
  const [jobDataArchive, setJobDataArchive] = useState<any>([]);
  const [jobLength, setJobLength] = useState<number>();
  const [jobArchiveLength, setJobArchiveLength] = useState<number>();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [dataPagination, setDataPagination] = useState<ResponseData>();

  const jobsApi = new JobApiRequest();

  useLayoutEffect(() => {
    setIsLoading(!isLoading);
  }, [storedUserEmployers]);

  const handleJobClick = (dataJob: any) => {
    setIsOpenModal(true);
    setActiveJob(dataJob);
  };
  const handleJobActiveClick = (dataJob: any) => {
    setIsOpenModal(false);
  };

  const handleToArhive = (id: any) => {
    jobsApi.moveToArchive(id).then((resp) => {
      console.log(resp);
      if (resp.success) {
        setIsLoading(!isLoading);
      }
    });
  };
  const handleDownArhive = (id: any) => {
    jobsApi.downToArchive(id).then((resp) => {
      console.log(resp);
      if (resp.success) {
        setIsLoading(!isLoading);
      }
    });
  };

  const handleApplicantFound = (id: any) => {
    jobsApi.moveApplicantFound(id).then((resp) => {
      console.log(resp);
      if (resp.success) {
        setIsLoading(!isLoading);
      }
    });
  };

  useLayoutEffect(() => {
    if (userEmployers != null) {
      jobsApi
        .list({ urlParams: `?employer=${userEmployers.id}&archived=false` })
        .then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setJobData(resp.data.results);
            //@ts-ignore
            setDataPagination(resp.data);
            //@ts-ignore
            setJobLength(resp.data.count);
          }
        });
      jobsApi
        .list({ urlParams: `?employer=${userEmployers.id}&archived=true` })
        .then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setJobDataArchive(resp.data.results);
            //@ts-ignore
            setJobArchiveLength(resp.data.count);
          }
        });
    }
  }, [isLoading]);

  const handleUpdatePage = (number: string | number) => {
    activeTab === 1
      ? jobsApi
          .list({
            urlParams: `?employer=${userEmployers.id}&archived=false&page=${number}`,
          })
          .then((resp: any) => {
            if (resp.success) {
              //@ts-ignore
              setJobData(resp.data.results);
              //@ts-ignore
              setDataPagination(resp.data);
            }
          })
      : jobsApi
          .list({
            urlParams: `?employer=${userEmployers.id}&archived=true&page=${number}`,
          })
          .then((resp: any) => {
            if (resp.success) {
              //@ts-ignore
              setJobData(resp.data.results);
              //@ts-ignore
              setDataPagination(resp.data);
            }
          });
  };

  useLayoutEffect(() => {
    if (userEmployers != null) {
      activeTab === 1
        ? jobsApi
            .list({
              urlParams: `?employer=${userEmployers.id}&archived=false`,
            })
            .then((resp: any) => {
              if (resp.success) {
                //@ts-ignore
                setJobData(resp.data.results);
                //@ts-ignore
                setDataPagination(resp.data);
              }
            })
        : jobsApi
            .list({
              urlParams: `?employer=${userEmployers.id}&archived=true`,
            })
            .then((resp: any) => {
              if (resp.success) {
                //@ts-ignore
                setJobData(resp.data.results);
                //@ts-ignore
                setDataPagination(resp.data);
              }
            });
    }
  }, [activeTab]);

  return (
    <Fragment>
      <Modal
        content={
          activeJob && (
            <VacancyСard
              //@ts-ignore
              key={activeJob?.id}
              //@ts-ignore
              dataCard={activeJob}
              onClick={() => {
                //@ts-ignore
                handleJobActiveClick(activeJob);
              }}
              modal={true}
            />
          )
        }
        onClose={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
      />
      <section className="section">
        <Header />
        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">Мои вакансии</h1>

          {userEmployers && (
            <div className="cardEmploers">
              <h3>{userEmployers.name}</h3>
              <a href={`mailto:${userEmployers.contact_person_email}`}>
                {userEmployers.contact_person_email}
              </a>
            </div>
          )}
        </div>

        <div className="containerTabs">
          <Buttons
            text={"Активные вакансии"}
            onClick={() => {
              setActiveTab(1);
            }}
            circle={jobLength}
            className={`whiteButton ${activeTab === 1 && "focus"}`}
          ></Buttons>
          <Buttons
            text={"Архив"}
            onClick={() => {
              setActiveTab(2);
            }}
            circle={jobArchiveLength}
            className={`whiteButton ${activeTab === 2 && "focus"}`}
          ></Buttons>
        </div>

        <div className="mySectionCardContainer">
          {activeTab === 1 ? (
            jobData.lenght === 0 ? (
              <p>Вакансий нет</p>
            ) : (
              jobData.map((item: any) => (
                <MyVacancyСard
                  key={item.id}
                  //@ts-ignore
                  dataCard={item}
                  onClick={() => {
                    handleJobActiveClick(item);
                  }}
                  onArchive={(e: any) => {
                    handleToArhive(e);
                  }}
                  onApplicantFound={(e: any) => {
                    handleApplicantFound(e);
                  }}
                  onRead={() => {
                    handleJobClick(item);
                  }}
                />
              ))
            )
          ) : activeTab === 2 && jobDataArchive.lenght === 0 ? (
            <p>Вакансий нет</p>
          ) : (
            jobDataArchive.map((item: any) => (
              <MyVacancyСard
                key={item.id}
                //@ts-ignore
                dataCard={item}
                onClick={() => {
                  handleJobActiveClick(item);
                }}
                onArchive={(e: any) => {
                  handleDownArhive(e);
                }}
                onApplicantFound={(e: any) => {
                  handleApplicantFound(e);
                }}
                onRead={() => {
                  handleJobClick(item);
                }}
                archive={true}
              />
            ))
          )}
        </div>
        {activeTab && jobData && jobData.length !== 0 && (
          <Pagination
            //@ts-ignore
            respData={dataPagination}
            handlePage={(e) => {
              handleUpdatePage(e);
            }}
          />
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

export default MyVacancyPage;
