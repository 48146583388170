import React from "react";
import Login from "../pages/Login/Login";
import HomePage from "../pages/HomePage/HomePage";
import Profile from "../pages/Profile/Profile";
import VacancyCreatePage from "../pages/VacancyCreatePage/VacancyCreatePage";
import VacancyPage from "../pages/VacancyPage/VacancyPage";
import AboutUsPage from "../pages/AboutUs/AboutUs";
import EventsPage from "../pages/EventsPage/EventsPage";
import ExcursiosPage from "../pages/ExcursiosPage/ExcursiosPage";
import MyVacancyPage from "../pages/MyVacancyPage/MyVacancyPage";
import NewsOpenPage from "../pages/NewsOpenPage/NewsOpenPage";
import UserAgreementPage from "../pages/UserAgreementPage/UserAgreementPage";

const isAuthenticated = localStorage.getItem("account");
const isAuthenticatedApplicant = localStorage.getItem("applicant");

export interface IRoute {
    path: string;
    element : React.ComponentType;
    exact?: boolean;
    params?: { [key: string]: string | number };
}

export enum RouteNames {
    LOGIN = '/login',
    HOMEPAGE = '/',
    PROFILE = '/profile',
    VACANCYCREATE = '/createjob',
    VACANCY = '/job',
    MYVACANCY = '/myjob',
    ABOUTUS = '/about',
    EVENTS = '/events',
    EXCURSIOS = '/excursios',
    NEWSOPENPAGE = '/newsopen',
    USERAGREEMENT = '/user-agreement',
}

export const navDate = [
  {
    id: 1,
    name: "О проекте",
    link: RouteNames.ABOUTUS,
  },
  {
    id: 2,
    name: "Вакансии",
    link: RouteNames.VACANCY,
  },
  {
    id: 3,
    name: "События",
    link: RouteNames.EVENTS,
  },
  {
    id: 4,
    name: "Экскурсии",
    link: RouteNames.EXCURSIOS, // Была ошибка в написании "Экскурсии"
  },
  {
    id: 6,
    name: "Пользовательское соглашение",
    link: RouteNames.USERAGREEMENT, // Укажите соответствующий маршрут или ссылку
  },
  !isAuthenticated ? // Если пользователь не аутентифицирован вообще
  {
    id: 5,
    name: "Я ищу сотрудника",
    link: RouteNames.LOGIN,
  }
  :
  isAuthenticatedApplicant ? // Если пользователь аутентифицирован как соискатель
  {}
  :
  { // Если пользователь аутентифицирован, но не как соискатель
    id: 5,
    name: "Я ищу сотрудника",
    link: RouteNames.MYVACANCY,
  }
];

export const publicRoutes: IRoute[] = [
  {path: RouteNames.HOMEPAGE, exact: true, element: HomePage},
  {path: RouteNames.LOGIN, exact: false, element: Login},
      {path: RouteNames.PROFILE, exact: false, element: Profile},
  {path: RouteNames.VACANCY, exact: false, element: VacancyPage},
  {path: RouteNames.ABOUTUS, exact: false, element: AboutUsPage},
  {path: RouteNames.EVENTS, exact: false, element: EventsPage},
  {path: RouteNames.EXCURSIOS, exact: false, element: ExcursiosPage},
  {path:`${RouteNames.NEWSOPENPAGE}/:id`, exact: false, element: NewsOpenPage, params: { params: ':id' }},
  {path: RouteNames.USERAGREEMENT, exact: false, element: UserAgreementPage},
]

export const privateRoutes: IRoute[] = [
    {path: RouteNames.HOMEPAGE, exact: true, element: HomePage},
    {path: RouteNames.LOGIN, exact: false, element: Login},
    {path: RouteNames.PROFILE, exact: false, element: Profile},
    {path: RouteNames.VACANCYCREATE, exact: false, element: VacancyCreatePage},
    {path: RouteNames.VACANCY, exact: false, element: VacancyPage},
    {path: RouteNames.MYVACANCY, exact: false, element: MyVacancyPage},
    {path: RouteNames.ABOUTUS, exact: false, element: AboutUsPage},
    {path: RouteNames.EVENTS, exact: false, element: EventsPage},
    {path: RouteNames.EXCURSIOS, exact: false, element: ExcursiosPage},
    {path: RouteNames.USERAGREEMENT, exact: false, element: UserAgreementPage},
    {path:`${RouteNames.NEWSOPENPAGE}/:id`, exact: false, element: NewsOpenPage, params: { params: ':id' }},
]