import React, { FC, Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "./styles.scss";
import icons from "../../assets/icons/icons";
import Buttons from "../../components/Buttons/Buttons";
import Footer from "../../components/Footer/Footer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../routes";
import apiConfig from "../../api/apiConfig";
import FeedApiRequest from "../../api/Feed/Feed";
import { formatDateIntlTimeDate } from "../../components/UI/functions/functions";

interface IDataNews {
  title: string;
  preview_image: string;
  text: string;
  publish_time: string | Date;
}

const NewsOpenPage: FC = () => {
  const navigation = useNavigate();
  const apiFeed = new FeedApiRequest();
  const { id } = useParams();

  const location = useLocation();
  const params = location.state?.params;

  const [dataNews, setDataNews] = useState<IDataNews>();

  useEffect(() => {
    params === "news"
      ? apiFeed.getEventsById(id + "/").then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setDataNews(resp.data);
          }
        })
      : apiFeed.getExcursionsById(id + "/").then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setDataNews(resp.data);
          }
        });
  }, [id]);

  return (
    <Fragment>
      <section className="section">
        <Header />
        <div className="sectionCardContainer__center">
          <div className="pageContainerTitle">
            <Buttons
              ico={icons.arrowLeft}
              text={"Вернутьcя на главную"}
              className="backButton"
              onClick={() => navigation(RouteNames.HOMEPAGE)}
            ></Buttons>
            <h1 className="titlePage"> {dataNews?.title}</h1>
            {/* <h5>
              {dataNews?.publish_time &&
                formatDateIntlTimeDate(dataNews?.publish_time)}
            </h5> */}
          </div>
          <img
            src={`${apiConfig.baseUrlMedia}${dataNews?.preview_image}`}
          ></img>
          <div className="newsContainer">
            <p dangerouslySetInnerHTML={{ __html: dataNews?.text || "" }}></p>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default NewsOpenPage;
