import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../routes";
import icons from "../../assets/icons/icons";
import ProfileComponents from "../../components/ProfileComponents/ProfileComponents";
import JobApiRequest from "../../api/Job/Job";
import { IJobCreate } from "../../models/IJobCreate";
import { fieldToArray } from "../../components/UI/functions/functions";
import FormInput from "../../components/FormInput/FormInput";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";

const VacancyCreatePage: FC = () => {
  const navigation = useNavigate();
  const { state } = useLocation();
  const { idJob, type } = state || {};
  const dispatch = useDispatch();
  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const jobsApi = new JobApiRequest();

  const [isJobOption, setJobOption] = useState<IJobCreate>();
  const [isJobData, setJobData] = useState<IJobCreate>();

  useEffect(() => {
    dispatch(DataPressActionCreators.clearDataPress());
    jobsApi.options().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        const sendToModerationData = resp.data?.actions?.create;
        setJobOption(sendToModerationData as IJobCreate);
      }
    });
  }, []);

  const classMappings = {
    salary_min: "colInput-1",
    salary_max: "colInput-2",
  };

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  const createJob = () => {
    jobsApi.create({ body: dataPress }).then((resp) => {
      console.log(resp);
      if (resp.success) {
        navigation(RouteNames.MYVACANCY);
      }
    });
  };
  const updateJob = () => {
    jobsApi.update({ id: idJob + "/", body: dataPress }).then((resp) => {
      console.log(resp);
      if (resp.success) {
        navigation(RouteNames.MYVACANCY);
      }
    });
  };

  useEffect(() => {
    if (idJob) {
      jobsApi.getById({ id: idJob }).then((resp) => {
        if (resp.success) {
          //@ts-ignore
          setJobData(resp.data);
          resp.data &&
            fieldToArray(resp.data).map((item) => {
              handleChange(item.key, item.value);
            });
        }
      });
    }
  }, []);

  return (
    <Fragment>
      <section className="section">
        <Header />

        <div className="pageContainerTitle">
          {type === "redact" ? (
            <Buttons
              ico={icons.arrowLeft}
              text={"Вернутьcя назад"}
              className="backButton"
              onClick={() => navigation(-1)}
            ></Buttons>
          ) : (
            <Buttons
              ico={icons.arrowLeft}
              text={"Вернутьcя на главную"}
              className="backButton"
              onClick={() => navigation(RouteNames.HOMEPAGE)}
            ></Buttons>
          )}

          <h1 className="titlePage">
            {type === "redact" ? "Редактирование вакансии" : "Создать вакансию"}
          </h1>
        </div>

        <div className="pageContainer col-3">
          <div className="containerFormJob">
            {isJobOption &&
              fieldToArray(isJobOption).map((item) => {
                if (item.key === "employer") {
                  return;
                }

                return (
                  //@ts-ignore
                  <div className={classMappings[item.key] || "colInput-3"}>
                    <FormInput
                      style={""}
                      value={dataPress[item.key]}
                      onChange={(e) => {
                        handleChange(item.key, e);
                      }}
                      mask={
                        ["salary_min", "salary_max"].includes(item.key)
                          ? "99999999"
                          : ""
                      }
                      subInput={item.value.label}
                      required={item.value.required}
                      error={""}
                      keyData={item.key}
                      options={item.value.choices}
                      friedlyInput={true}
                      placeholder={
                        item.value.style && item.value.style.placeholder
                      }
                      helpText={item.value && item.value.help_text}
                      textArea={item.key === "description" && true}
                    ></FormInput>
                  </div>
                );
              })}
            <Buttons
              text={"Разместить"}
              onClick={() => {
                type === "redact" ? updateJob() : createJob();
              }}
              className="colInput-3 orangeButton"
            ></Buttons>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default VacancyCreatePage;
