import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import icons from "../../assets/icons/icons";
import ProfileComponents from "../../components/ProfileComponents/ProfileComponents";
import CoverComponent from "../../components/CoverComponent/CoverComponent";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import VacancyСard from "../../components/VacancyСard/VacancyСard";
import Modal from "../../components/Modal/Modal";
import FeedApiRequest from "../../api/Feed/Feed";
import NewsCard, { NewsItem } from "../../components/NewsCard/NewsCard";

const EventsPage: FC = () => {
  const navigation = useNavigate();
  const apiFeed = new FeedApiRequest();
  const [dataEvents, setDataEvents] = useState<any>();

  useEffect(() => {
    apiFeed.getEvents().then((resp) => {
      if (resp.success) {
        setDataEvents(resp.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <section className="section">
        <Header />
        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">События</h1>
        </div>

        <div className="sectionCardContainer">
          {dataEvents &&
            dataEvents?.results
              ?.slice(-6)
              .reverse()
              .map((item: NewsItem) => (
                <NewsCard key={item.id} dataCard={item} news={true} />
              ))}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default EventsPage;
