import React, { FC, useEffect, useState } from "react";
import FormInput from "../FormInput/FormInput";
import Buttons from "../Buttons/Buttons";
import { ISendModeration } from "../../models/ISendModeration";
import { fieldToArray } from "../UI/functions/functions";
import { ISendLogin } from "../../models/ISendLogin";
import { Link, useNavigate } from "react-router-dom";
import icons from "../../assets/icons/icons";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { AuthActionCreators } from "../../store/reducers/auth/action-creator";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";
import EmployersApiRequest from "../../api/Employers/Employers";
import { RouteNames } from "../../routes";

interface iLoginForm {
  title: string;
  subTitle: string;
  entry?: boolean;
  dataOption: ISendModeration | ISendLogin | undefined;
}

const LoginComponents: FC<iLoginForm> = ({
  title,
  subTitle,
  dataOption,
  entry,
}) => {
  const employersApi = new EmployersApiRequest();

  const dispatch = useDispatch();
  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const navigate = useNavigate();

  const [isHelp, setIsHelp] = useState<boolean>(false);

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  const { isAuth, error, isLoading } = useTypeSelector(
    (state) => state.authReducer
  );

  const submit = () => {
    entry
      ? dispatch(
          //@ts-ignore
          AuthActionCreators.login(dataPress.username, dataPress.password)
        )
      : dispatch(
          //@ts-ignore
          AuthActionCreators.registration(dataPress)
        );
  };

  useEffect(() => {
    if (isAuth) {
      navigate(RouteNames.MYVACANCY);
    }
  }, [isAuth]);

  return (
    <div className="formContainer">
      {error != "" && (
        <ErrorMessage
          type={"error"}
          message={error}
          onClick={() => {
            submit();
          }}
          onClose={() => {
            dispatch(AuthActionCreators.setErr(""));
          }}
        />
      )}
      <div className="formContainerField">
        {title && <h2 className="formTitle">{title}</h2>}
        {subTitle && <h3 className="formSubTitle">{subTitle}</h3>}
        {dataOption &&
          fieldToArray(dataOption).map((item) => {
            return (
              <FormInput
                style={""}
                value={dataPress[item.key]}
                onChange={(e) => {
                  handleChange(item.key, e);
                }}
                subInput={item.value.label}
                required={item.value.required}
                type={item.value.type}
                mask={item.value.style && item.value.style.mask}
                placeholder={item.value.style && item.value.style.placeholder}
                error={false}
                keyData={item.key}
                friedlyInput={true}
              />
            );
          })}

        {entry && (
          <div
            className="passwordRecoveryButton"
            onClick={() => setIsHelp(!isHelp)}
          >
            Восстановление пароля
            <img src={icons.HelpCircle} className={"helpIco"}></img>
            {isHelp && (
              <div className={`helpMessage ${isHelp && "active"}`}>
                {dataOption &&
                  fieldToArray(dataOption).map((item) => {
                    return item.value &&
                      item.value.style &&
                      item.value.style.tip_message ? (
                      <div key={item.key}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.value.style.tip_message,
                          }}
                        ></p>
                      </div>
                    ) : null;
                  })}
              </div>
            )}
          </div>
        )}

        <Buttons
          className="orangeBtnForm"
          text={isLoading ? "Загрузка" : "Отправить"}
          onClick={() => submit()}
        />
      </div>
    </div>
  );
};

export default LoginComponents;
