import React, { FC, Fragment, useState } from "react";
import icons from "../../assets/icons/icons";
import Buttons from "../Buttons/Buttons";
import "./styles.scss";
import FilePickerModal from "../UI/FilePickerModal/FilePickerModal";
import apiConfig from "../../api/apiConfig";

interface IImage {
  extension: string;
  file: string;
  original_name: string;
  upload_time: string;
}

interface IAvatarProps {
  avatarUrl: IImage[];
  setFiles: (e: File) => void;
}

const AvatarPicker: FC<IAvatarProps> = ({ avatarUrl, setFiles }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <Fragment>
      {isOpenModal && (
        <FilePickerModal
          onClose={() => setIsOpenModal(false)}
          setFiles={(e: any) => setFiles(e)}
          type={"image"}
        />
      )}
      <div className="avatarPicker">
        <img
          src={`${apiConfig.baseUrlMedia}${
            avatarUrl && avatarUrl.length > 0
              ? avatarUrl[0].file
              : //@ts-ignore
                avatarUrl && avatarUrl?.file
          }`}
          className="avatar"
        />
        <div className="avatarContainer">
          <h1>Фотография профиля</h1>
          <h3>Не более 2 МБ в формате jpg или png</h3>
          <Buttons
            text={"Загрузить фото"}
            onClick={() => {
              setIsOpenModal(true);
            }}
            className="avatarButton"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AvatarPicker;
