// NewsCard.tsx
import React, { FC } from "react";
import "./styles.scss";
import apiConfig from "../../api/apiConfig";
import {
  formatDateIntlDateTime,
  formatDateIntlTimeDate,
} from "../UI/functions/functions";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";

export interface NewsItem {
  id: number;
  preview_image: string;
  text: string;
  publish_time: string;
  excursion_time?: string;
  title?: string;
  is_open?: boolean;
}

interface NewsCardProps {
  dataCard: NewsItem;
  news?: boolean;
}

const NewsCard: FC<NewsCardProps> = ({ dataCard, news }) => {
  const navigation = useNavigate();

  const openNews = () => {
    //@ts-ignore
    navigation(`${RouteNames.NEWSOPENPAGE}/${dataCard.id}`, {
      state: { params: news ? "news" : "excurs" },
    });
  };

  console.log("====================================");
  console.log("dataCard", dataCard);
  console.log("====================================");

  return (
    <div
      key={dataCard.id}
      className="newsCardContainer"
      onClick={() => {
        openNews();
      }}
    >
      <div className="previewImageContainer">
        <img
          src={`${apiConfig.baseUrlMedia}${dataCard.preview_image}`}
          alt="News Preview"
          className="previewImage"
        />
        {dataCard.is_open && (
          <h3 className="dataEvent">
            {dataCard.is_open ? "Регистрация открыта" : "Регистрация закрыта"}
          </h3>
        )}
      </div>
      <div className="infoNewsContainer">
        {/* <p className="dateTimePublish">
          {formatDateIntlTimeDate(
            dataCard?.publish_time ? dataCard?.publish_time : ""
          )}
        </p> */}
        <p className="newsText">{dataCard.title}</p>
      </div>
    </div>
  );
};

export default NewsCard;
