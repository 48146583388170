import React, { FC, Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CoverComponent from "../../components/CoverComponent/CoverComponent";
import VacancyСard from "../../components/VacancyСard/VacancyСard";
import "./styles.scss";
import icons from "../../assets/icons/icons";
import NewsCard, { NewsItem } from "../../components/NewsCard/NewsCard";
import Buttons from "../../components/Buttons/Buttons";
import Footer from "../../components/Footer/Footer";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import Modal from "../../components/Modal/Modal";
import FeedApiRequest from "../../api/Feed/Feed";
import { Link, useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import SingularComponent from "react-singular-component";

const AboutUsPage: FC = () => {
  const navigation = useNavigate();
  return (
    <Fragment>
      <section className="section">
        <Header />
        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">О проекте</h1>
        </div>
        <div className="sectionCardContainer__two">
          <p>
            Моя первая работа <br />
            Наш веб-сервис предоставляет уникальную возможность находить
            идеальные вакансии для молодежи в разнообразных профессиональных
            областях нашего региона.
            <br />
            Независимо от того, ищете ли вы позицию в сфере обслуживания, или же
            стремитесь занять должности в крупных региональных компаниях, с
            нашей информационной базой вы сможете быстро и эффективно подобрать
            подходящую вакансию или стажировку.
            <br />
            Процесс трудоустройства стал еще проще: достаточно пройти
            авторизацию через социальную сеть ВКонтакте, и вы готовы к откликам
            на интересующие вас вакансии и стажировки в Белгородской области. Мы
            стремимся обеспечить легкость и удобство в поиске работы, делая этот
            процесс максимально эффективным для вас.
            <br />
            Наш сервис регулярно обновляется огромным объемом данных от местных
            компаний, обеспечивая вам актуальную информацию о вакансиях в
            Белгородской области.
          </p>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AboutUsPage;
