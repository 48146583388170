import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import icons from "../../assets/icons/icons";
import ProfileComponents from "../../components/ProfileComponents/ProfileComponents";
import { useDispatch } from "react-redux";
import ApplicantsApiRequest from "../../api/Applicants/Applicants";
import { IApplications } from "../../models/IApplications";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import ErrorMessage from "../../components/UI/ErrorMassage/ErrorMassage";
import { IError } from "../../models/IError";

const Profile: FC = () => {
  const navigation = useNavigate();

  const dispatch = useDispatch();
  const applicantApi = new ApplicantsApiRequest();
  const [optionApplicant, setOptionApplicant] = useState<IApplications>();
  const [dataProfile, setDataProfile] = useState<IApplications>();
  const [isErr, setIsErr] = useState<IError>({
    id: "",
    message: "",
    type: "error",
  });

  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  useEffect(() => {
    applicantApi.applicantUpdateOption().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        setOptionApplicant(resp.data?.actions.update);
        applicantApi.getByUser().then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setDataProfile(resp.data);
          } else {
            setIsErr({ id: "", message: resp.message, type: "error" });
          }
        });
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
  }, []);

  const updateApplicants = () => {
    applicantApi
      .update({ id: dataPress.id + "/", body: dataPress })
      .then((resp) => {
        if (resp.success) {
          //@ts-ignore
          setDataProfile(resp.data);

          localStorage.setItem("applicant", JSON.stringify(resp.data));
          setIsErr({ id: "", message: "Сохранено", type: "success" });
          navigation(RouteNames.HOMEPAGE);
        } else {
          setIsErr({ id: "", message: resp.message, type: "error" });
        }
      });
  };

  return (
    <Fragment>
      {isErr.message != "" && (
        <ErrorMessage
          //@ts-ignore
          type={isErr.type}
          message={isErr.message}
          onClose={() => {
            setIsErr({ id: "", message: "", type: "]" });
          }}
        />
      )}
      <section className="section">
        <Header />

        <Fragment>
          <div className="pageContainerTitle">
            <Buttons
              ico={icons.arrowLeft}
              text={"Вернуться на главную"}
              className="backButton"
              onClick={() => navigation(RouteNames.HOMEPAGE)}
            ></Buttons>
            <h1 className="titlePage">Профиль</h1>
            <h6 className="subTitlePage">
              Заполните данные и расскажите о себе
            </h6>
          </div>

          <div className="pageContainer">
            <ProfileComponents
              dataOption={optionApplicant}
              dataProfile={dataProfile}
              onSave={() => {
                updateApplicants();
              }}
            />
          </div>
        </Fragment>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Profile;
