import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import icons from "../../assets/icons/icons";
import ProfileComponents from "../../components/ProfileComponents/ProfileComponents";
import CoverComponent from "../../components/CoverComponent/CoverComponent";
import SearchComponent, {
  Category,
} from "../../components/SearchComponent/SearchComponent";
import VacancyСard from "../../components/VacancyСard/VacancyСard";
import Modal from "../../components/Modal/Modal";
import JobApiRequest from "../../api/Job/Job";
import { IError } from "../../models/IError";
import ErrorMessage from "../../components/UI/ErrorMassage/ErrorMassage";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import Pagination, {
  ResponseData,
} from "../../components/Pagination/Pagination";

const VacancyPage: FC = () => {
  const navigation = useNavigate();
  const jobsApi = new JobApiRequest();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenMOdalError, setIsOpenModalError] = useState<boolean>(false);
  const [stringSearch, setStringSearch] = useState<string>("");
  const [activeJob, setActiveJob] = useState<any>();
  const [jobData, setJobData] = useState<any>([]);
  const [isToggleSearch, setToggleSearch] = useState<boolean>(false);
  const [isFilterSearch, setFilterSearch] = useState<string>("");
  const [jobSearchActivite, setJobSearchActivite] = useState<any>([]);
  const [jobSearchCategory, setJobSearchCategory] = useState<any>([]);
  const [dataPagination, setDataPagination] = useState<ResponseData>();
  const [activitiesData, setActivitesData] = useState<any>([]);
  const [cateGoriesData, setCategoriesData] = useState<any>([]);

  const searchString = useTypeSelector(
    (state: any) => state.SearchReducer.dataPress
  );

  useEffect(() => {
    if (searchString) {
      handleSearch(searchString?.search);
    }
  }, []);

  const [isErr, setIsErr] = useState<IError>({
    id: "",
    message: "",
    type: "error",
  });

  const handleJobClick = (dataJob: any) => {
    setIsOpenModal(true);
    setActiveJob(dataJob);
  };
  const handleJobActiveClick = (dataJob: any) => {
    jobsApi.respond(dataJob.id).then((resp) => {
      if (resp.success) {
        setIsOpenModal(false);
        jobsApi.list({ urlParams: `?archived=false` }).then((resp) => {
          if (resp.success) {
            //@ts-ignore
            setJobData(resp.data.results);
          } else {
            setIsErr({ id: "", message: resp.message, type: "error" });
          }
        });
      } else {
        setIsOpenModalError(true);
        // setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
  };
  useEffect(() => {
    jobsApi.list({ urlParams: `?archived=false` }).then((resp: any) => {
      if (resp.success) {
        //@ts-ignore
        setJobData(resp.data.results);
        //@ts-ignore
        setDataPagination(resp.data);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
    jobsApi.activities().then((resp: any) => {
      if (resp.success) {
        setActivitesData(resp.data);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
    jobsApi.categories().then((resp: any) => {
      if (resp.success) {
        setCategoriesData(resp.data);
      } else {
        setIsErr({ id: "", message: resp.message, type: "error" });
      }
    });
  }, []);

  const handleSearch = (text?: any) => {
    jobsApi
      .list({
        urlParams: `?archived=false&search=${
          text ? text : searchString.search ? searchString.search : ""
        }&job_type=${isToggleSearch ? 2 : ""}${
          jobSearchCategory.length !== 0
            ? jobSearchCategory
                .map((item: any) => `&job_category=${item.id}`)
                .join("")
            : ""
        }${
          jobSearchActivite.length !== 0
            ? jobSearchActivite
                .map((item: any) => `&job_activity=${item.id}`)
                .join("")
            : ""
        }&municipality=${isFilterSearch}`,
      })
      .then((resp: any) => {
        if (resp.success) {
          //@ts-ignore
          setJobData(resp.data.results);
          //@ts-ignore
          setDataPagination(resp.data);
        } else {
          setIsErr({ id: "", message: resp.message, type: "error" });
        }
      });
  };

  const handleUpdatePage = (number: string | number) => {
    jobsApi
      .list({
        urlParams: `?archived=false&page=${number}&search=${
          searchString.search ? searchString.search : ""
        }&job_type=${isToggleSearch ? 2 : ""}${
          jobSearchCategory.length !== 0
            ? jobSearchCategory
                .map((item: any) => `&job_category=${item.id}`)
                .join("")
            : ""
        }${
          jobSearchActivite.length !== 0
            ? jobSearchActivite
                .map((item: any) => `&job_activity=${item.id}`)
                .join("")
            : ""
        }`,
      })
      .then((resp: any) => {
        if (resp.success) {
          //@ts-ignore
          setJobData(resp.data.results);
          //@ts-ignore
          setDataPagination(resp.data);
        } else {
          setIsErr({ id: "", message: resp.message, type: "error" });
        }
      });
  };

  useEffect(() => {
    if (stringSearch !== "") {
      console.log("====================================");
      console.log("stringSearch", stringSearch);
      console.log("====================================");
      const apiUrl = stringSearch?.replace(
        "http://rabota.belregion.ru/api/job-openings/",
        ""
      );
      jobsApi
        .list({
          urlParams: apiUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            setJobData(resp.data.results);
          } else {
            setIsErr({ id: "", message: resp.message, type: "error" });
          }
        });
    }
  }, [stringSearch]);

  useEffect(() => {
    handleSearch();
  }, [jobSearchCategory, isToggleSearch, jobSearchActivite, isFilterSearch]);

  return (
    <Fragment>
      {isErr.message != "" && (
        <ErrorMessage
          //@ts-ignore
          type={isErr.type}
          message={isErr.message}
          onClose={() => {
            setIsErr({ id: "", message: "", type: "]" });
          }}
        />
      )}
      <Modal
        content={
          activeJob && (
            <VacancyСard
              //@ts-ignore
              key={activeJob?.id}
              //@ts-ignore
              dataCard={activeJob}
              onClick={() => {
                //@ts-ignore
                handleJobActiveClick(activeJob);
              }}
              modal={true}
            />
          )
        }
        onClose={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        classModal="modalVacancy"
      />
      <Modal
        content={
          <div className="modalContainer">
            <h1 className="titleModal">Подтвердите данные</h1>
            <h3 className="textModal">
              Чтобы откликнуться на вакансию вам необходимо заполнить
              недостающие данные в своем профиле.
            </h3>
            <Buttons
              text={"Перейти в профиль"}
              onClick={() => {
                navigation(RouteNames.PROFILE);
              }}
              className="orangeButton"
            />
          </div>
        }
        onClose={() => setIsOpenModalError(false)}
        isOpen={isOpenMOdalError}
        classModal="classModal"
        classBgModal="classBgModal"
      />
      <section className="section">
        <Header />
        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">Вакансии</h1>
        </div>
        <div className="containerSearchNested">
          <SearchComponent
            //@ts-ignore
            onSearch={(e) => {
              handleSearch(e);
            }}
            //@ts-ignore
            onFilter={(e) => {
              setFilterSearch(e);
            }}
            //@ts-ignore
            onToggle={(e) => {
              setToggleSearch(!isToggleSearch);
            }}
            dataActivites={activitiesData}
            dataCategory={cateGoriesData}
            categoryActiveData={(e: Category) => {
              setJobSearchCategory(e);
            }}
            activitesActiveData={(e: Category) => {
              setJobSearchActivite(e);
            }}
          />
        </div>

        <div className="sectionCardContainer">
          {jobData && jobData.length === 0 ? (
            <p>Вакансий нет</p>
          ) : (
            jobData &&
            jobData
              .slice(-6)
              .reverse()
              .map((item: any) => (
                <VacancyСard
                  key={item.id}
                  //@ts-ignore
                  dataCard={item}
                  onClick={() => {
                    handleJobActiveClick(item);
                  }}
                  onRead={() => {
                    handleJobClick(item);
                  }}
                  isError={(e: IError) => setIsErr(e)}
                />
              ))
          )}
        </div>
        {jobData && jobData.length !== 0 && (
          <Pagination
            //@ts-ignore
            respData={dataPagination}
            handlePage={(e) => {
              handleUpdatePage(e);
            }}
            handleSearch={(e) => {
              setStringSearch(e);
            }}
          />
        )}
      </section>
      <Footer />
    </Fragment>
  );
};

export default VacancyPage;
