import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";
import LoginComponents from "../../components/LoginComponents/LoginComponents";
import EmployersApiRequest from "../../api/Employers/Employers";
import { ISendModeration } from "../../models/ISendModeration";
import { ISendLogin } from "../../models/ISendLogin";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AuthActionCreators } from "../../store/reducers/auth/action-creator";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";

const Login: FC = () => {
  const navigation = useNavigate();
  const employersApi = new EmployersApiRequest();

  const [isSendToModeration, setSendToModeration] = useState<ISendModeration>();
  const [loginData, setLoginData] = useState<ISendLogin>();

  const dispatch = useDispatch();

  const { isAuth, error, isLoading, data } = useTypeSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    employersApi.options().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        const sendToModerationData = resp.data?.actions?.["send-to-moderation"];
        //@ts-ignore
        const loginData = resp.data?.actions?.["login"];
        setSendToModeration(sendToModerationData as ISendModeration);
        setLoginData(loginData as ISendLogin);
      }
    });
    dispatch(DataPressActionCreators.clearDataPress());
  }, []);

  return (
    <Fragment>
      <section className="section">
        <Header />

        <div className="pageContainerTitle">
          <Buttons
            ico={icons.arrowLeft}
            text={"Вернутьcя на главную"}
            className="backButton"
            onClick={() => navigation(RouteNames.HOMEPAGE)}
          ></Buttons>
          <h1 className="titlePage">Регистрация и авторизация работодателя</h1>
          <h6 className="subTitlePage">
            Для того, чтобы оставить вакансию или стажировку на территории
            Белгородской области, заполните форму и пройдите модерацию
          </h6>
        </div>

        <div className="pageContainer">
          <Fragment>
            {data ? (
              <div className="verificatedContainer">
                <div className="containerVerif">
                  <img src={icons.checkVerified} />
                  <h1>Запрос отправлен</h1>
                  <h4>
                    Ваша анкета появится на платформе после проверки модератором
                  </h4>
                </div>
              </div>
            ) : (
              <LoginComponents
                title={"Регистрация"}
                subTitle={""}
                dataOption={isSendToModeration}
              />
            )}
          </Fragment>
          <LoginComponents
            title={"Вход"}
            subTitle={""}
            dataOption={loginData}
            entry={true}
          />
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Login;
