import { AppDispatch } from "../../index";
import { IUser, IUserReg } from "../../../models/IUser";
import { AuthActionEnum, SetAuthAction, SetErrorAction, SetIsLoadingAction, SetTokenAction, SetUserAction } from "./types";
import { IToken } from "../../../models/IToken";
import jwt from 'jwt-decode';
import UserApiRequest from "../../../api/User/Users";
import ApplicantsApiRequest from "../../../api/Applicants/Applicants";

export const AuthVkActionCreators = {
    setUser: (user: IUser): SetUserAction => ({ type: AuthActionEnum.SET_USER, payload: user }),
    setToken: (token: IToken): SetTokenAction => ({ type: AuthActionEnum.SET_TOKEN, payload: token }),
    setIsAuth: (auth: boolean): SetAuthAction => ({ type: AuthActionEnum.SET_AUTH, payload: auth }),
    setErr: (payload: string): SetErrorAction => ({ type: AuthActionEnum.SET_ERROR, payload }),
    setIsLoading: (payload: boolean): SetIsLoadingAction => ({ type: AuthActionEnum.SET_IS_LOADING, payload }),
    loginVK: (accessToken:string, refreshToken:string) => async (dispatch: AppDispatch) => {
        dispatch(AuthVkActionCreators.setIsLoading(true));
        const userData = new UserApiRequest();
        const applicantsData = new ApplicantsApiRequest();
   
        setTimeout(()=>{
         
            try {
             
                        const tokens = {refreshToken, accessToken};
                        //@ts-ignore
                        dispatch(AuthVkActionCreators.setToken(tokens));
                        tokens.accessToken && localStorage.setItem('accessVK', tokens.accessToken)
                        tokens.refreshToken && localStorage.setItem('refreshVK', tokens.refreshToken)
                        //@ts-ignore
                        const decodeJwt = jwt(tokens.refreshToken) || '';
                        
                        //@ts-ignore
                        if (decodeJwt && decodeJwt.user_id) {
                            //@ts-ignore
                            applicantsData.getByUser().then((resp)=>{
                                
                                if(resp.success){
                                    if (resp.data) {
                                        //@ts-ignore
                                        const dataUser: { id?: string, email?: string, fio?:string, resume?: string, first_name?: string, last_name?: string, patronymic?: string, phone_number?:string, birth_date?:string, user?:number, avatar?:string } = resp.data;
                                    
                                        const user = {
                                            id: dataUser.id,
                                            email: dataUser.email,
                                            phone_number: dataUser.phone_number,
                                            first_name: dataUser.first_name,
                                            last_name: dataUser.last_name,
                                            patronymic: dataUser.patronymic,
                                            fio: dataUser.fio,
                                            resume: dataUser.resume,
                                            birth_date: dataUser.birth_date,
                                            user: dataUser.user,
                                            avatar: dataUser.avatar,
                                        };
                                        
                                    
                                        localStorage.setItem('applicant', JSON.stringify(user));
                                        dispatch(AuthVkActionCreators.setIsAuth(true));
                                             //@ts-ignore
                                        dispatch(AuthVkActionCreators.setUser({  
                                            id: dataUser.id,
                                              //@ts-ignore
                                            email: dataUser.email,
                                            phone_number: dataUser.phone_number,
                                            first_name: dataUser.first_name,
                                            last_name: dataUser.last_name,
                                            patronymic: dataUser.patronymic,
                                            fio: dataUser.fio,
                                            resume: dataUser.resume,
                                            birth_date: dataUser.birth_date,
                                            user: dataUser.user,
                                            avatar: dataUser.avatar,
                                        }));
                                    }
                                   
                                    
                               
                                } else {
                                    dispatch(AuthVkActionCreators.setErr('Ошибка получения пользователя'));
                                }
                            })
                          
                        }
               
            } catch (e) {
                // dispatch(AuthVkActionCreators.setErr('Произошла ошибка при авторизации'));
            }
            dispatch(AuthVkActionCreators.setIsLoading(false));
        }, 0)
      
    },
   
    logout: () => async (dispatch: AppDispatch) => {
        dispatch(AuthVkActionCreators.setIsLoading(true));
        localStorage.removeItem('auth');
        localStorage.removeItem('username');
        localStorage.removeItem('account');
        localStorage.removeItem('applicant');
        localStorage.removeItem('access');
        localStorage.removeItem('accessVK');
        localStorage.removeItem('refreshVK');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accountEmployers');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refresh');
        localStorage.removeItem('userVK');
        dispatch(AuthVkActionCreators.setIsAuth(false));
        dispatch(AuthVkActionCreators.setUser({} as IUser));
        dispatch(AuthVkActionCreators.setIsLoading(false));
    }
}
